import { getAPIPort } from "../utils";
import { AdministratorsAPI } from "./administrators";
import { AuthAPI } from "./auth/auth";
import { BillingsAPI } from "./billing";
import { BusinessProfilesAPI } from "./businessProfiles";
import { LocationsAPI } from "./locations";
import { LockSystemsAPI } from "./lockSystems/lockSystems";
import { LocksAPI } from "./locks";
import { NotificationsAPI } from "./notifications";
import { OfficeTypesAPI } from "./officeTypes";
import { OfficesAPI } from "./offices";
import { RentedOfficesAPI } from "./rentedOffices";
import { RentersAPI } from "./renters";
import { SharedLocksAPI } from "./sharedLocks";
import { DocumentationTemplatesAPI } from "./templates";
import { TicketsAPI } from "./tickets/tickets";
import { UserRolesAPI } from "./userRoles/userRoles";
import { UsersAPI } from "./users";

const baseUrl = "https://api.elasticwork.space:" + getAPIPort();

export const api = {
  auth: AuthAPI(baseUrl),
  businessProfiles: BusinessProfilesAPI(baseUrl),
  users: UsersAPI(baseUrl),
  offices: OfficesAPI(baseUrl),
  rentedOffices: RentedOfficesAPI(baseUrl),
  renters: RentersAPI(baseUrl),
  billings: BillingsAPI(baseUrl),
  locations: LocationsAPI(baseUrl),
  locks: LocksAPI(baseUrl),
  sharedLocks: SharedLocksAPI(baseUrl),
  lockSystems: LockSystemsAPI(baseUrl),
  officeTypes: OfficeTypesAPI(baseUrl),
  userRoles: UserRolesAPI(baseUrl),
  tickets: TicketsAPI(baseUrl),
  documents: DocumentationTemplatesAPI(baseUrl),
  administrators: AdministratorsAPI(baseUrl),
  notifications: NotificationsAPI(baseUrl)
}
