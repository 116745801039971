import { Button, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ColumnType, TableBuilder } from "../../components/tables/tableBuilder";
import { FaExpand, FaPencil, FaPlus } from "react-icons/fa6";
import { api } from "../../../api";
import { OfficeLocation } from "../../../api/locations";
import { CreateLocationModal } from "../../components/modals/location/createLocationModal";
import { EditLocationModal } from "../../components/modals/location/editLocationModal";
// import { useNavigate } from "react-router-dom";
import useErrorHandling from "../../../hooks/useErrorHandling";

export function LocationsPage() {
  const [locations, setLocations] = useState<OfficeLocation[] | null>(null);
  const [location, setLocation] = useState<OfficeLocation | null>(null);
  // const navigate = useNavigate();

  const handleError = useErrorHandling();

  const createLocationModalDisclosure = useDisclosure();
  const editLocationModalDisclosure = useDisclosure();

  const fullscreenDisclosure = useDisclosure();

  // const viewLocation = (location: OfficeLocation) => {
  //   navigate(`/dashboard/locations/${location.id}/view`);
  // };

  const editLocation = (location: OfficeLocation) => {
    setLocation(location);
    editLocationModalDisclosure.onOpen();
  };

  useEffect(() => {
    api.locations.list()
      .then(async response => {
        setLocations(response.data);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    createLocationModalDisclosure.isOpen,
    editLocationModalDisclosure.isOpen
  ]);

  return (
    <>
      <CreateLocationModal disclosure={createLocationModalDisclosure} />
      <EditLocationModal disclosure={editLocationModalDisclosure} location={location} />
      <div className="p-4">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-bold text-2xl">Локации</span>
            <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}><FaExpand /></Button>
          </div>
          <div className="flex flex-row gap-4 items-center">
            <Button
              startContent={<FaPlus />}
              color="primary"
              variant="solid"
              className="max-w-fit"
              onClick={() => createLocationModalDisclosure.onOpen()}
            >
              Создать локацию
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            fullscreenDisclosure={fullscreenDisclosure}
            columns={[
              {
                key: "display_name",
                label: "Название",
                type: ColumnType.String,
                sortable: true
              },
              {
                key: "city",
                label: "Город",
                type: ColumnType.String,
                sortable: true
              },
              {
                key: "address",
                label: "Адрес",
                type: ColumnType.String,
                sortable: true
              },
              {
                key: "district",
                label: "Район",
                type: ColumnType.String,
                sortable: true
              },
              {
                key: "metro_station",
                label: "Станция метро",
                type: ColumnType.String,
                sortable: true
              },
              {
                key: "actions",
                label: "Действия",
                type: ColumnType.Actions,
                actions: [
                  // {
                  //   icon: <FaEye />,
                  //   onClick: (_value, row) => { viewLocation(row) }
                  // },
                  {
                    icon: <FaPencil />,
                    onClick: (_value, row) => { editLocation(row) }
                  }
                ]
              }              
            ]}
            data={locations || []}
            rowsPerPage={10}
          />
        </div>
      </div>
    </>
  );
}
