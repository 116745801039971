import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { toast } from "react-toastify";
import { api } from "../../../../api";
import { getIdFromOfficeHandbook, getIdFromRenterHandbook } from "../../../../utils/getIdFromHandbooks";
import useErrorHandling from "../../../../hooks/useErrorHandling";
import { useState } from "react";

interface IRentedOfficeCreateForm {
  office: number;
  renter: number;
  price: number;
  description: string;
  start_date: string;
  end_date: string;
}

const createRentedOfficeFields: InputField[] = [
  { label: "Офис", name: "office", placeholder: "Офис", type: "office", isRequired: true },
  { label: "Арендатор", name: "renter", placeholder: "Арендатор", type: "renter", isRequired: true },
  { label: "Цена", name: "price", placeholder: "Цена", type: "text", isRequired: true },
  { label: "Описание", name: "description", placeholder: "Описание", type: "text", isRequired: true },
  { label: "Дата начала", name: "start_date", placeholder: "Дата начала", type: "datetime-local", isRequired: true },
  { label: "Дата окончания", name: "end_date", placeholder: "Дата окончания", type: "datetime-local", isRequired: true },
];

export function CreateRentedOfficeModal({ disclosure }: { disclosure: ReturnType<typeof useDisclosure> }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);
  
  const handleError = useErrorHandling();

  const onSubmit = (data: IRentedOfficeCreateForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const renter = await getIdFromRenterHandbook(String(data.renter));
      const office = await getIdFromOfficeHandbook(String(data.office));

      api.rentedOffices.create({
          office: Number(office),
          renter: Number(renter),
          price: data.price,
          description: data.description,
          start_date: data.start_date,
          end_date: data.end_date,
        })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder title="Создание арендованного офиса"  modalErrors={modalErrors} fields={createRentedOfficeFields} isOpen={isOpen} isSensitiveData={false} onOpenChange={onOpenChange} onSubmit={onSubmit} />
  );
}
