import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../../../api";
import { Popover, PopoverTrigger, Avatar, PopoverContent, Listbox, ListboxItem, Navbar, NavbarContent, NavbarMenuToggle, NavbarBrand, NavbarMenu, Divider } from "@nextui-org/react";
import { motion } from "framer-motion";
import { FaPencil, FaGear, FaDoorClosed, FaBuilding, FaBuildingCircleCheck, FaGears, FaHouse, FaLock, FaMessage, FaMoneyBill, FaUser, FaUsers, FaInfo, FaArrowDown, FaEllipsis, FaFile, FaBell } from "react-icons/fa6";
import { Admin, AdminPermissions } from "../../../api/auth/auth";
import { HiArrowRight, HiArrowLeft } from "react-icons/hi2";
import { DarkModeToggler } from "../darkmode/darkModeToggler";
import { getPortal, hasAllPermissions } from "../../../utils";
import { NotificationsList } from "../notifications/notifications";

interface PlainNavbarLink {
  path: string;
  icon: any;
  title: string;
  permissions?: string[];
}

interface DropdownNavbarLink extends PlainNavbarLink {
  children: PlainNavbarLink[];
}

type NavbarLink = PlainNavbarLink | DropdownNavbarLink;

function isDropdownNavbarLink(link: NavbarLink): link is DropdownNavbarLink {
  return (link as DropdownNavbarLink).children !== undefined;
}

export default function LayoutNavbar() {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const adminUser = useAppSelector(state => state.user.currentUser);
  const businessProfile = useAppSelector(state => state.businessProfile.businessProfile);
  const [links, setLinks] = useState<NavbarLink[]>([]);

  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const desktopVariants = {
    collapsed: {
      width: 67
    },
    expanded: {
      width: 288
    }
  };

  useEffect(() => {
    if (businessProfile.id !== 0) {
      setLinks([
        { path: '/dashboard', icon: FaHouse, title: 'Главная', permissions: [] },
        { path: '/dashboard/tickets', icon: FaMessage, title: 'Тикеты', permissions: [AdminPermissions.ViewLandlordTickets] },
        {
          path: 'main', icon: FaInfo, title: "Коворкинг", children: [
            { path: '/dashboard/renters', icon: FaUser, title: 'Арендаторы', permissions: [AdminPermissions.ManageLandlordRenters, AdminPermissions.ManageLandlordTenants] },
            { path: '/dashboard/users', icon: FaUsers, title: 'Сотрудники', permissions: [AdminPermissions.ManageLandlordTenants] },
            { path: '/dashboard/offices', icon: FaDoorClosed, title: 'Офисы', permissions: [AdminPermissions.ManageLandlordProperties] },
            { path: '/dashboard/locations', icon: FaBuilding, title: 'Локации', permissions: [AdminPermissions.ManageLandlordProperties] },
            { path: '/dashboard/locks', icon: FaLock, title: 'Замки', permissions: [AdminPermissions.ManageLandlordLocks] },
            { path: '/dashboard/sharedLocks', icon: FaLock, title: 'Общие замки', permissions: [AdminPermissions.ManageLandlordLocks] },
            { path: '/dashboard/rentedOffices', icon: FaBuildingCircleCheck, title: 'Офисы в аренде', permissions: [AdminPermissions.ManageLandlordProperties] },
            { path: '/dashboard/payments', icon: FaMoneyBill, title: 'Платежи', permissions: [AdminPermissions.ViewLandlordReports] },
          ]
        },
        { path: '/dashboard/system', icon: FaGears, title: 'Параметры', permissions: [AdminPermissions.ViewManageLandlordProfile] },
        { path: '/dashboard/documents', icon: FaFile, title: 'Документация', permissions: [AdminPermissions.CreatingDocuments] },
      ]);
    } else {
      setLinks([{ path: '/dashboard/selectProfile', icon: FaHouse, title: 'Выбор профиля' }]);
    }
  }, [businessProfile]);

  return (
    <>
      <motion.div
        className="hidden lg:flex flex-col p-4 justify-start gap-6 select-none h-screen rounded-none shadow-none max-h-screen bg-background dark:bg-zinc-900"
        initial={false}
        animate={collapsed ? 'collapsed' : 'expanded'}
        variants={desktopVariants}
      >
        <div className="flex flex-row items-center gap-4">
          <a href='#' className='p-1 hidden lg:block' onClick={toggleCollapsed}>
            {collapsed ? <HiArrowRight className="text-xl" /> : <HiArrowLeft className="text-xl" />}
          </a>

          <motion.div animate={{ opacity: collapsed ? 0 : 1 }}>
            <a href="/dashboard/">
              <img className="logo h-16" src="/logo.svg" alt="Elastic Management" />
            </a>
          </motion.div>
        </div>

        {!collapsed && (
          <>
            {businessProfile.id !== 0 ? (
              <div className="flex flex-row items-center justify-between w-full">
                <div className="flex flex-col">
                  <span className="font-semibold text-sm text-default-500">Бизнес-профиль:</span>
                  <span className="font-semibold text-sm">{businessProfile.display_name}</span>
                </div>
                <Link to="/dashboard/selectProfile" className="font-semibold text-sm text-primary">Сменить</Link>
              </div>
            ) : (
              <div className="flex flex-row items-center justify-between w-full">
                <span className="font-semibold text-sm">Бизнес-профиль не выбран</span>
              </div>
            )}
          </>
        )}

        <div className="flex flex-col gap-2 flex-grow overflow-y-auto overflow-x-clip">
          {links.map(link => (
            <>
              {!collapsed && isDropdownNavbarLink(link) && (
                <DropdownNavLink
                  link={link}
                  collapsed={collapsed}
                />
              )}

              {!isDropdownNavbarLink(link) && (
                <NavLink
                  link={link}
                  collapsed={collapsed}
                />
              )}
            </>
          ))}
        </div>

        <div className="flex flex-col justify-end gap-4">
          <DarkModeToggler collapsed={collapsed} />
          <Notifications collapsed={collapsed} />
          <Divider />
          <NavUser collapsed={collapsed} user={adminUser} />
        </div>
      </motion.div>
      <MobileNav links={links} user={adminUser} />
    </>
  )
}

function MobileNav({ links, user }: { links: NavbarLink[], user: Partial<Admin> }) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const businessProfile = useAppSelector(state => state.businessProfile.businessProfile);
  const location = useLocation();

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  const onClick = useCallback(() => {
    setIsMenuOpen(false);
  }, [setIsMenuOpen]);

  return (
    <Navbar isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen} className="lg:hidden">
      <NavbarContent>
        <NavbarMenuToggle aria-label={isMenuOpen ? "Close menu" : "Open menu"} />

        <NavbarBrand>
          <img className="logo h-12" src="/logo.svg" alt="Elastic Management" />
        </NavbarBrand>

        <NavbarMenu className="p-4" portalContainer={getPortal()}>
          <>
            <>
              {businessProfile.id !== 0 ? (
                <div className="flex flex-row items-center justify-between w-full">
                  <div className="flex flex-col">
                    <span className="font-semibold text-sm text-default-500">Бизнес-профиль:</span>
                    <span className="font-semibold text-sm">{businessProfile.display_name}</span>
                  </div>
                  <Link to="/dashboard/selectProfile" className="font-semibold text-sm text-primary">Сменить</Link>
                </div>
              ) : (
                <div className="flex flex-row items-center justify-between w-full">
                  <span className="font-semibold text-sm">Бизнес-профиль не выбран</span>
                </div>
              )}
            </>
            {links.map(link => (
              <MobileNavLink link={link} key={link.path + "m"} collapsed={false} onClick={onClick} />
            ))}
            <div className="flex-grow" />
            <DarkModeToggler collapsed={false} />
            <Notifications collapsed={false} />
            <NavUser collapsed={false} user={user} />
            <div className="h-40" />
          </>
        </NavbarMenu>
      </NavbarContent>
    </Navbar>
  )
}

function DropdownNavLink({ link, collapsed }: { link: DropdownNavbarLink, collapsed: boolean }) {
  const [localCollapsed, setLocalCollapsed] = useState<boolean>(true);

  const toggleLocalCollapseState = useCallback(() => {
    setLocalCollapsed(prev => !prev);
  }, []);

  return link.children.length > 0 ? (
    <div className="flex flex-col cursor-pointer">
      <div
        className={
          `flex flex-row items-center gap-4 py-2 rounded-md text-foreground-600 bg-default bg-opacity-0 hover:bg-opacity-30 transition-all ` +
          `${collapsed ? 'hover:bg-opacity-0' : 'px-2'}`
        }
        onClick={toggleLocalCollapseState}
      >
        <motion.div className="flex flex-row items-center justify-between w-full" animate={{ opacity: collapsed ? 0 : 1 }}>
          <div className="flex flex-row gap-4 items-center">
            <div className={`flex flex-col items-center justify-center min-w-8 min-h-8 rounded-lg bg-default bg-opacity-30 text-default-500`}>
              <FaEllipsis />
            </div>
            <span className="text-sm font-semibold text-nowrap">{link.title}</span>
          </div>
          <FaArrowDown className="text-sm" />
        </motion.div>
      </div>

      <motion.div
        initial={false}
        animate={{ opacity: localCollapsed ? 0 : 1, maxHeight: localCollapsed ? 0 : '1000px' }}
        transition={{ duration: 0.2 }}
        className={`flex flex-col gap-2 overflow-hidden ${localCollapsed ? '' : 'px-2 pt-2'} transition-all`}
      >
        {link.children.map(children => (
          <NavLink link={children} key={children.path} collapsed={collapsed} />
        ))}
      </motion.div>
    </div>
  ) : null;
}

function NavLink({ link, collapsed }: { link: NavbarLink, collapsed: boolean }) {
  const userPermissions = useAppSelector(state => state.user.permissions);

  const permissionsCheck = link.permissions ? hasAllPermissions(link.permissions, userPermissions) : true;

  return permissionsCheck ?
    isDropdownNavbarLink(link) ?
      link.children.map(children => (
        <NavLink link={children} key={children.path} collapsed={collapsed} />
      ))
      : (
        <Link
          to={link.path}
          className={
            `flex flex-row items-center gap-4 py-2 rounded-md text-foreground-600 bg-default bg-opacity-0 hover:bg-opacity-30 transition-all ` +
            `${collapsed ? 'hover:bg-opacity-0' : 'px-2'}`
          }
        >
          <div className={`flex flex-col items-center justify-center min-w-8 min-h-8 rounded-lg bg-default bg-opacity-30 text-default-500`}>
            <link.icon className="text-medium" />
          </div>
          <motion.div animate={{ opacity: collapsed ? 0 : 1 }}>
            <span className="text-sm font-semibold text-nowrap">{link.title}</span>
          </motion.div>
        </Link>
      ) : null;
}

function MobileNavLink({ link, collapsed, onClick }: { link: NavbarLink, collapsed: boolean, onClick: (e: any) => void }) {
  const userPermissions = useAppSelector(state => state.user.permissions);

  const permissionsCheck = link.permissions ? hasAllPermissions(link.permissions, userPermissions) : true;

  return permissionsCheck ?
    isDropdownNavbarLink(link) ?
      link.children.map(children => (
        <NavLink link={children} key={children.path} collapsed={collapsed} />
      ))
      : (
        <Link
          to={link.path}
          className={
            `flex flex-row items-center gap-4 py-2 rounded-md text-foreground-600 bg-default bg-opacity-0 hover:bg-opacity-30 transition-all ` +
            `${collapsed ? 'hover:bg-opacity-0' : 'px-2'}`
          }
          onClick={onClick}
        >
          <div className={`flex flex-col items-center justify-center min-w-8 min-h-8 rounded-lg bg-default bg-opacity-30 text-default-500`}>
            <link.icon className="text-medium" />
          </div>
          <motion.div animate={{ opacity: collapsed ? 0 : 1 }}>
            <span className="text-sm font-semibold text-nowrap">{link.title}</span>
          </motion.div>
        </Link>
      ) : null;
}

function Notifications({ collapsed }: { collapsed: boolean }) {
  const unreadCount = useAppSelector(state => state.notifications.unreadCount);

  return (
    <Popover showArrow={false} placement="top" portalContainer={document.getElementById("main")!} backdrop="blur">
      <PopoverTrigger className="shadow-none border-none bg-none outline-none">
        <button
          className={
            `flex flex-row items-center gap-4 py-2 rounded-md text-foreground-600 bg-default bg-opacity-0 hover:bg-opacity-30 transition-all ` +
            `${collapsed ? 'hover:bg-opacity-0' : 'px-2'}`
          }>
          <div className={`flex flex-col items-center justify-center min-w-8 min-h-8 rounded-lg bg-default bg-opacity-30`}>
            <FaBell className="text-medium top-2 relative" />
            <span className={`top-2 left-4 w-4 h-4 relative z-10 rounded-full text-[10px] 
              ${unreadCount > 0 ? 'bg-danger animate-pulse' : 'bg-default'}`}>{unreadCount}</span>
          </div>
          <motion.div animate={{ opacity: collapsed ? 0 : 1 }}>
            <span className="text-sm font-semibold">Уведомления</span>
          </motion.div>
        </button>
      </PopoverTrigger>
      <PopoverContent className='min-w-96 max-w-96'>
        <NotificationsList />
      </PopoverContent>
    </Popover>
  )
};

function NavUser({ collapsed, user }: { collapsed: boolean, user: Partial<Admin> }) {
  const navigate = useNavigate();

  const onUserAction = (key: any) => {
    switch (key) {
      case 'profile':
        navigate('/dashboard/me');
        break;
      case 'settings':
        navigate('/dashboard/settings');
        break;
      case 'exit':
        api.auth.logout()
          .then(() => {
            localStorage.clear();
            window.location.href = "/";
          })
          .catch(err => {
            console.error(err);
            toast.error("Произошла ошибка при попытке выйти из аккаунта!");
          });
        break;
      default:
        break;
    }
  }

  return (
    <Popover showArrow={false} placement="top" portalContainer={document.getElementById("main")!}>
      <PopoverTrigger className="shadow-none border-none bg-none outline-none">
        <button className="inline-flex items-center gap-2 rounded-small outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 z-10 aria-expanded:scale-[0.97] aria-expanded:opacity-70 subpixel-antialiased transition-transform">
          <div>
            <Avatar size='sm' name={`${user.first_name?.charAt(0)}`} />
          </div>
          <motion.div animate={{ opacity: collapsed ? 0 : 1 }} className="flex flex-col gap-1">
            <span className='text-sm text-nowrap'>{`${user.first_name} ${user.last_name}`}</span>
          </motion.div>
        </button>
      </PopoverTrigger>
      <PopoverContent className='p-1 min-w-48'>
        <Listbox aria-label="Действия" onAction={onUserAction}>
          <ListboxItem startContent={<FaPencil />} key="profile">Профиль</ListboxItem>
          <ListboxItem startContent={<FaGear />} key="settings">Настройки</ListboxItem>
          <ListboxItem startContent={<FaDoorClosed />} key="exit" className="text-danger" color="danger">
            Выйти
          </ListboxItem>
        </Listbox>
      </PopoverContent>
    </Popover>
  )
}
