import { Column, ActionsColumn, ColumnType } from "../app/components/tables/tableBuilder";

export function getNormalizedTime(date: string): string {
  return new Date(date).toLocaleString().slice(12, 17)
}

export function getNormalizedDate(date: string): string {
  return new Date(date).toLocaleString().replace(/\//g, ".").slice(0, -3)
}

export function getNormalizedDateTime(date: string): string {
  return new Date(date).toLocaleString().replace(/\//g, ".").slice(0, -3)
}

export function getPortal() {
  return document.getElementById("main")!
}

export function isActionsColumn(column: Column): column is ActionsColumn {
  return column.type === ColumnType.Actions;
}

export function hasAllPermissions(scopePermissions: string[], userPermissions: string[]) {
  return scopePermissions.every(permission => userPermissions.includes(permission));
}

export function getEnvironment(): "production" | "development" {
  return (window.location.hostname.indexOf("dev") !== -1 || window.location.hostname.indexOf("localhost") !== -1) ? "development" : "production";
}

export function getAPIPort(): number {
  return getEnvironment() == "development" ? 8200 : 8100;
}

export function sortAlphabeticallyByKey(array: any[], key: string) {
  return array.sort((a, b) => {
    if(a[key] < b[key]) return -1;
    if(a[key] > b[key]) return 1;
    return 0;
  });
}