import { Button, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { api } from "../../../../api";
import { Lock, LockPasscode } from "../../../../api/locks";
import { toast } from "react-toastify";
import { ColumnType, TableBuilder } from "../../../components/tables/tableBuilder";
import { FaExpand, FaPencil, FaPlus } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { CreatePasscodeModal } from "../../../components/modals/lock/createPasscodeModal";
import { EditPasscodeModal } from "../../../components/modals/lock/editPasscodeModal";
import useErrorHandling from "../../../../hooks/useErrorHandling";

export function LockPage() {
  const { id } = useParams() as { id: string };
  const [lock, setLock] = useState<Lock | null>(null);
  const [passcodes, setPasscodes] = useState<LockPasscode[] | null>(null);
  const [passcode, setPasscode] = useState<LockPasscode | null>(null);

  const handleError = useErrorHandling();

  const createPasscodeModalDisclosure = useDisclosure();
  const editPasscodeModalDisclosure = useDisclosure();

  const fullscreenDisclosure = useDisclosure();

  const editPasscode = (passcode: LockPasscode) => {
    setPasscode(passcode);
    editPasscodeModalDisclosure.onOpen();
  }

  useEffect(() => {
    api.locks.get(Number(id))
      .then(response => {
        setLock(response.data)
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });

    api.locks.listPasscodes(Number(id))
      .then(response => {
        setPasscodes(response.data)
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    createPasscodeModalDisclosure.isOpen,
    editPasscodeModalDisclosure.isOpen
  ])

  return (
    <>
      {lock && (
        <>
          <CreatePasscodeModal disclosure={createPasscodeModalDisclosure} lock={lock} />
          <EditPasscodeModal disclosure={editPasscodeModalDisclosure} lock={lock} passcode={passcode} />
          <div className="p-4">
            <div className="flex flex-row justify-between w-full items-center mb-4">
              <div className="flex flex-row gap-4 items-center mb-2">
                <span className="font-bold text-2xl">Замок {lock.display_name}</span>
                <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}><FaExpand /></Button>
              </div>
              <div className="flex flex-row gap-4 items-center mb-2">
                <Button startContent={<FaPlus />} color="primary" variant="solid" className="max-w-fit" onClick={() => createPasscodeModalDisclosure.onOpen()}>Создать пароль</Button>
              </div>
            </div>
            <div className="flex flex-col gap-2 mb-2">
              <TableBuilder
                fullscreenDisclosure={fullscreenDisclosure}
                columns={[
                  {
                    label: "Пароль",
                    key: "passcode",
                    type: ColumnType.String
                  },
                  {
                    key: "actions",
                    label: "Действия",
                    type: ColumnType.Actions,
                    actions: [
                      {
                        icon: <FaPencil />,
                        onClick: (_value, row) => { editPasscode(row) }
                      }
                    ]
                  }                  
                ]}
                data={passcodes || []}
                rowsPerPage={10}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}