import crud, { ApiResponse } from "../crud"

export interface Token {
  access_token: string;
  token_type: string;
}

export interface Admin {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  telegram: number;
}

export interface RegisterAdminParams {
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}

type PermissionsReponse = [
  {
    business_profile: {
      id: number;
      display_name: string;
    }
    role: string,
    permissions: {
      [key: string]: string
    }
  }
];

export enum AdminPermissions {
  ManageLandlordProfile = "manage_landlord_profile",
  ManageLandlordProperties = "manage_landlord_properties",
  ManageLandlordTenants = "manage_landlord_tenants",
  ViewLandlordReports = "view_landlord_reports",
  ManageLandlordAdmins = "manage_landlord_admins",
  ManageLandlordLocks = "manage_landlord_locks",
  ViewManageLandlordProfile = "view_manage_landlord_profile",
  ManageLandlordRenters = "manage_landlord_renters",
  ManageLandlordHardware = "manage_landlord_hardware",
  ViewLandlordTickets = "view_landlord_tickets",
  CreatingDocuments = "creating_documents"
}

export function AuthAPI(baseUrl: string) {
  return {
    login: async (username: string, password: string) => {
      const formData = new URLSearchParams();
      formData.append("username", username);
      formData.append("password", password);
      
      return await crud.post(`${baseUrl}/admin/auth/login`, formData, false, "application/x-www-form-urlencoded") as Token;
    },
    logout: async () => {
      return await crud.post(`${baseUrl}/admin/auth/logout`, {}, true) as ApiResponse<{}>;
    },
    validateToken: async () => {
      return await crud.post(`${baseUrl}/admin/auth/validate`, {}, true) as ApiResponse<{}>;
    },
    getCurrentAdminUserInfo: async () => {
      return await crud.get(`${baseUrl}/admin/auth/me`, undefined, true) as ApiResponse<Admin>;
    },
    getMyPermissions: async () => {
      return await crud.get(`${baseUrl}/admin/auth/permissions`, undefined, true) as ApiResponse<PermissionsReponse>;
    },
    generateTelegramLink: async() => {
      return await crud.get(`${baseUrl}/admin/auth/generate-telegram-link`, undefined, true) as ApiResponse<{link: string}>;
    },
    register: async (params: RegisterAdminParams) => {
      return await crud.post(`${baseUrl}/admin/auth/register`, params, false) as ApiResponse<Admin>;
    },
    connectBusinessProfile: async (hash: string) => {
      return await crud.post(`${baseUrl}/admin/auth/connect-business-profile/${hash}`, null, true) as ApiResponse<{}>;
    }
  }
}
