import { Button, Checkbox, Input, Link } from "@nextui-org/react";
import FileUpload from "../../components/fileUpload/fileUpload";
import { useForm } from "react-hook-form";

export function SettingsPage({ displayTitle = true }: { displayTitle?: boolean }) {
  const preloadForm = useForm();

  return (
    <div className={`${displayTitle == true ? 'p-4' : ''}`}>
      {displayTitle == true ? (
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-semibold text-2xl">Настройки</span>
          </div>
        </div>
      ) : null}
      <div className="flex max-md:flex-col md:flex-row gap-4">
        <div className="flex flex-col gap-4 w-full">
          <div className="flex flex-col p-4 gap-4 bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row justify-between items-center">
              <span className="font-medium">Персональная информация</span>
              <Button
                color="primary"
                isDisabled
                variant="solid"
                size="sm">
                Сохранить
              </Button>
            </div>
            <div className="flex flex-row items-center gap-4">
              <Input
                label="Имя"
                variant="bordered"
                placeholder="Ваше имя"
              />
              <Input
                label="Фамилия"
                variant="bordered"
                placeholder="Ваша фамилия"
              />
            </div>
            <div className="flex flex-row items-center gap-4">
              <Input
                label="Адрес почты"
                variant="bordered"
                placeholder="Адрес электронной почты"
              />
            </div>
            <div className="flex flex-row items-center gap-4">
              <Input
                label="Имя пользователя"
                variant="bordered"
                placeholder="Ваше имя пользователя"
              />
            </div>
            <div className="flex flex-row items-center gap-4">
              <Checkbox>Не оповещайте меня о уведомлениях</Checkbox>
            </div>
          </div>
          <div className="flex flex-col p-4 gap-4 bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row justify-between items-center">
              <span className="font-medium">Смена пароля</span>
              <Button
                color="primary"
                isDisabled
                variant="solid"
                size="sm">
                Сохранить
              </Button>
            </div>
            <div className="flex flex-row items-center gap-4">
              <Input
                label="Старый пароль"
                variant="bordered"
                placeholder="Введите ваш текущий пароль"
              />
            </div>
            <div className="flex flex-row items-center gap-4">
              <Input
                label="Новый пароль"
                variant="bordered"
                placeholder="Введите ваш новый пароль"
              />
            </div>
            <div className="flex flex-row items-center gap-4">
              <Input
                label="Новый пароль (повторно)"
                variant="bordered"
                placeholder="Введите ваш новый пароль ещё раз"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col p-4 gap-4 bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row justify-between items-center">
              <span className="font-medium">Фото профиля</span>
            </div>
            <div className="flex flex-row justify-start items-center gap-4">
              <div className="bg-gray-500 min-h-16 min-w-16 rounded-full block" />
              <div className="flex flex-col gap-1">
                <span>Это ваше текущее фото профиля</span>
                <span className="font-medium text-red-500 cursor-pointer">Удалить</span>
              </div>
            </div>
            <div className="flex flex-row items-center gap-4">
              <FileUpload
                control={preloadForm.control}
                name="avatar_file"
              />
            </div>
          </div>
          <div className="flex flex-col p-4 gap-4 bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row justify-between items-center">
              <span className="font-medium">Telegram</span>
              <Button
                color="success"
                variant="flat"
                size="sm">
                Привязан
              </Button>
            </div>
            <span className="text-foreground-500">Используйте нашего Telegram бота для более быстрого и удобного доступа к функциям личного кабинета.</span>
            <Link showAnchorIcon href="#">Узнать больше</Link>
          </div>
        </div>
      </div>
    </div>
  )
}