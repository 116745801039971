import rootStore from "../../app/store";
import crud, { ApiResponse } from "../crud"

export interface Lock {
  id: number;
  display_name: string;
  lock_system: number;
  lock_service_id: number;
}

export interface LockCreate {
  display_name: string;
  lock_system: number;
  lock_service_id: number;
}

export interface LockUpdate {
  display_name: string;
  lock_system: number;
  lock_service_id: number;
}

export interface LockPasscode {
  id: number;
  passcode: string;
  lock_id: number;
}

export interface LockPasscodeCreate {
  passcode: string;
  lock_id: number;
}

export interface LockPasscodeUpdate {
  passcode: string;
}

export function LocksAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/locks/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Lock[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/locks/${id}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Lock>;
    },
    create: async (lockData: LockCreate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/locks/?business_profile_id=${businessProfileId}`, lockData, true) as ApiResponse<Lock>;
    },
    update: async (id: number, lockData: LockUpdate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/admin/locks/${id}?business_profile_id=${businessProfileId}`, lockData, true) as ApiResponse<{}>;
    },
    delete: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/admin/locks/${id}?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    },
    listPasscodes: async (lockId: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/locks/${lockId}/passcodes?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<LockPasscode[]>;
    },
    createPasscode: async (lockId: number, passcodeData: LockPasscodeCreate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/locks/${lockId}/passcodes?business_profile_id=${businessProfileId}`, passcodeData, true) as ApiResponse<LockPasscode>;
    },
    updatePasscode: async (lockId: number, passcodeId: number, passcodeData: LockPasscodeUpdate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/admin/locks/${lockId}/passcodes/${passcodeId}?business_profile_id=${businessProfileId}`, passcodeData, true) as ApiResponse<{}>;
    },
    deletePasscode: async (lockId: number, passcodeId: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/admin/locks/${lockId}/passcodes/${passcodeId}?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    }
  }
}
