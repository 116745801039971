import { Button, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ColumnType, TableBuilder } from "../../components/tables/tableBuilder";
import { FaExpand, FaPencil, FaPlus } from "react-icons/fa6";
import { api } from "../../../api";
import { Office } from "../../../api/offices";
import { CreateOfficeModal } from "../../components/modals/office/createOfficeModal";
import { EditOfficeModal } from "../../components/modals/office/editOfficeModal";
import useErrorHandling from "../../../hooks/useErrorHandling";
import { AdminPermissions } from "../../../api/auth/auth";

export function OfficesPage() {
  const [offices, setOffices] = useState<Office[] | null>(null);
  const [office, setOffice] = useState<Office | null>(null);

  const handleError = useErrorHandling();

  const createOfficeModalDisclosure = useDisclosure();
  const editOfficeModalDisclosure = useDisclosure();

  const fullscreenDisclosure = useDisclosure();

  // const viewOffice = (office: Office) => {
  //   navigate(`/dashboard/offices/${office.id}/view`);
  // };

  const editOffice = (office: Office) => {
    setOffice(office);
    editOfficeModalDisclosure.onOpen();
  };

  useEffect(() => {
    api.offices.list()
      .then(async response => {
        setOffices(response.data);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    createOfficeModalDisclosure.isOpen,
    editOfficeModalDisclosure.isOpen
  ]);

  return (
    <>
      <CreateOfficeModal disclosure={createOfficeModalDisclosure} />
      <EditOfficeModal disclosure={editOfficeModalDisclosure} office={office} />
      <div className="p-4">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-bold text-2xl">Офисы</span>
            <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}><FaExpand /></Button>
          </div>
          <div className="flex flex-row gap-4 items-center">
            <Button
              startContent={<FaPlus />}
              color="primary"
              variant="solid"
              className="max-w-fit"
              onClick={() => createOfficeModalDisclosure.onOpen()}
            >
              Создать офис
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            fullscreenDisclosure={fullscreenDisclosure}
            columns={[
              {
                key: "display_name",
                label: "Название",
                type: ColumnType.String,
                sortable: true
              },
              {
                key: "area",
                label: "Площадь",
                type: ColumnType.Number,
                sortable: true
              },
              {
                key: "price",
                label: "Цена",
                type: ColumnType.Number,
                permissions: [AdminPermissions.ViewLandlordReports],
                sortable: true
              },
              {
                key: "floor",
                label: "Этаж",
                type: ColumnType.Number,
                sortable: true
              },
              {
                key: "room_count",
                label: "Комнаты",
                type: ColumnType.Number,
                sortable: true
              },
              {
                key: "office_type",
                label: "Тип офиса",
                type: ColumnType.OfficeType,
                sortable: true
              },
              {
                key: "location",
                label: "Локация",
                type: ColumnType.Location,
                sortable: true
              },
              {
                key: "actions",
                label: "Действия",
                type: ColumnType.Actions,
                actions: [
                  // {
                  //   icon: <FaEye />,
                  //   onClick: (_value, row) => { viewOffice(row) }
                  // },
                  {
                    icon: <FaPencil />,
                    onClick: (_value, row) => { editOffice(row) }
                  }
                ]
              }              
            ]}
            data={offices || []}
            rowsPerPage={10}
          />
        </div>
      </div>
    </>
  );
}
