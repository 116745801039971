import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { OfficeLocation } from "../../../../api/locations";
import { Button, Divider, useDisclosure } from "@nextui-org/react";
import { api } from "../../../../api";
import { toast } from "react-toastify";
import { FaCity, FaMapPin, FaBuilding, FaTrainSubway, FaPencil, FaPlus, FaElevator, FaRubleSign } from "react-icons/fa6";
import { EditLocationModal } from "../../../components/modals/location/editLocationModal";
import { Office } from "../../../../api/offices";
import { CreateOfficeWithLocationModal } from "../../../components/modals/office/createOfficeWithLocationModal";
import useErrorHandling from "../../../../hooks/useErrorHandling";
import { sortAlphabeticallyByKey } from "../../../../utils";

export function LocationPage() {
  const { id } = useParams() as { id: string };
  const [location, setLocation] = useState<OfficeLocation | null>(null);
  const [officies, setOfficies] = useState<Office[] | null>(null);

  const handleError = useErrorHandling();

  const createOfficeModalDisclosure = useDisclosure();
  const editLocationModalDisclosure = useDisclosure();

  useEffect(() => {
    api.locations.get(Number(id))
      .then(response => {
        setLocation(response.data);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });

    api.locations.getOfficesByLocation(Number(id))
      .then(response => {
        const sortedData = sortAlphabeticallyByKey(response.data, "display_name");
        setOfficies(sortedData);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    id,
    setLocation,
    createOfficeModalDisclosure.isOpen,
    editLocationModalDisclosure.isOpen
  ]);

  return (
    <div>
      <CreateOfficeWithLocationModal disclosure={createOfficeModalDisclosure} location={location} />
      <EditLocationModal disclosure={editLocationModalDisclosure} location={location} />
      {location && (
        <div className="flex flex-col gap-2 p-2 md:p-4">
          <div className="flex flex-col gap-2 mb-2">
            <span className="text-2xl font-bold">{location.display_name}</span>
            <div className="flex flex-row gap-2 items-center">
              <FaCity />
              <span className="text-sm">Город: {location.city}</span>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <FaMapPin />
              <span className="text-sm">Адрес: {location.address}</span>
            </div>
            {location.district && (
              <div className="flex flex-row gap-2 items-center">
                <FaBuilding />
                <span className="text-sm">Район: {location.district}</span>
              </div>
            )}
            {location.metro_station && (
              <div className="flex flex-row gap-2 items-center">
                <FaTrainSubway />
                <span className="text-sm">Станция метро: {location.metro_station}</span>
              </div>
            )}
            <div className="flex flex-col gap-2 mt-2">
              <Button startContent={<FaPencil />} color="primary" variant="solid" className="max-w-fit" onClick={() => editLocationModalDisclosure.onOpen()}>Редактировать</Button>
            </div>
          </div>
          <Divider className="mt-2 mb-2" />
          <div className="flex flex-col gap-2 mb-2">
            <div className="flex flex-row justify-between w-full items-center mb-2">
              <span className="text-xl font-bold">Офисы</span>
              <div className="flex flex-col gap-2 mt-2">
                <Button startContent={<FaPlus />} color="primary" variant="solid" className="max-w-fit" onClick={() => createOfficeModalDisclosure.onOpen()}>Создать</Button>
              </div>
            </div>
            {officies && (
              <div className="flex flex-row flex-wrap gap-3 items-center mt-2">
                {officies.map((office, index) => (
                  <Link to={`/dashboard/location/${id}/office/${office.id}`} className="flex flex-col bg-background border-2 border-default-200 rounded-xl cursor-pointer select-none" key={index}>
                    <div className="flex flex-col gap-3 p-4">
                      <span className="text-medium font-bold">{office.display_name}</span>
                      <div className="flex flex-row gap-2 items-center">
                        <FaElevator />
                        <span className="text-sm">{office.floor} этаж</span>
                      </div>
                      <div className="flex flex-row gap-2 items-center">
                        <FaRubleSign />
                        <span className="text-sm">{office.price} руб.</span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}