import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { User } from "../../../api/users";
import { api } from "../../../api";
import { toast } from "react-toastify";
import { LoadingSpinner } from "../loadingSpinner";
import { PageBuilder, ViewPage } from "./pageBuilder";
import { InputField } from "../modals/modalBuilder";
import { getIdFromRenterHandbook, getIdFromUserRoleHandbook } from "../../../utils/getIdFromHandbooks";
import { Column, ColumnType } from "../tables/tableBuilder";

interface IUserEditForm {
  username?: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  phone_number?: string;
  role?: number;
  renter?: number;
  password?: string;
  telegram?: number;
}

const editUserFields: InputField[] = [
  { label: "Имя пользователя", name: "username", placeholder: "username", type: "text", isRequired: true },
  { label: "Имя", name: "first_name", placeholder: "Имя", type: "text", isRequired: true },
  { label: "Отчество", name: "middle_name", placeholder: "Отчество", type: "text", isRequired: false },
  { label: "Фамилия", name: "last_name", placeholder: "Фамилия", type: "text", isRequired: true },
  { label: "Номер телефона", name: "phone_number", placeholder: "+7955...", type: "text", isRequired: true },
  { label: "Роль", name: "role", placeholder: "Роль", type: "user-role", isRequired: true },
  { label: "Арендатор", name: "renter", placeholder: "Арендатор", type: "renter", isRequired: false },
  { label: "Пароль", name: "password", placeholder: "Пароль", type: "password", isRequired: false }
];

const viewUserColumns: Column[] = [
  {
    label: "Имя пользователя",
    key: "username",
    type: ColumnType.String
  },
  {
    label: "Имя",
    key: "first_name",
    type: ColumnType.String
  },
  {
    label: "Фамилия",
    key: "last_name",
    type: ColumnType.String
  },
  {
    label: "Отчество",
    key: "middle_name",
    type: ColumnType.String
  },
  {
    label: "Номер телефона",
    key: "phone_number",
    type: ColumnType.String
  },
  {
    label: "Арендатор",
    key: "renter",
    type: ColumnType.Renter
  },
  {
    label: "Роль",
    key: "role",
    type: ColumnType.UserRole
  }
];

export function UserViewPage() {
  const { id } = useParams();
  const [user, setUser] = useState<User | null>(null);

  const navigate = useNavigate();

  const onSubmit = async (data: IUserEditForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const renter = await getIdFromRenterHandbook(String(data.renter));
      const role = await getIdFromUserRoleHandbook(String(data.role));

      api.users.update(user!.id, {
        username: data.username,
        first_name: data.first_name,
        middle_name: data.middle_name,
        last_name: data.last_name,
        phone_number: data.phone_number,
        role: Number(role),
        renter: Number(renter),
        password: data.password,
      })
        .then(_data => {
          toast.success("Успешно отредактировано!")
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          toast.error("Произошла ошибка при редактировании пользователя!");
          resolve(false);
        });
    });
  }

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.users.delete(user!.id)
        .then(_data => {
          navigate("/dashboard/users/");
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          resolve(false);
          toast.error("Произошла ошибка при удалении пользователя!");
        });
    });
  };

  useEffect(() => {
    api.users.get(Number(id))
      .then(response => {
        setUser(response.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке данных!");
      });
  }, [id]);

  return (
    <>
      {!user && (
        <LoadingSpinner />
      )}
      {user && (
        <PageBuilder
          title="Пользователь"
          fields={editUserFields}
          onSubmit={onSubmit}
          onDelete={onDelete}
          isSensitiveData={true}
          submitButtonText="Сохранить"
          viewEntityComponent={<ViewPage item={user} columns={viewUserColumns} />}
          defaultValues={{
            username: user?.username,
            first_name: user?.first_name,
            middle_name: user?.middle_name,
            last_name: user?.last_name,
            phone_number: user?.phone_number,
            role: user?.role,
            renter: user?.renter,
            telegram: user?.telegram
          }} />
      )}
    </>
  )
}