import {
  Modal, ModalContent, ModalHeader, ModalBody, Input,
  ModalFooter, Button, useDisclosure, Checkbox
} from "@nextui-org/react";
import { RegisterOptions, useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { ConfirmDeleteModal } from "./service/confirmDeleteModal";
import { ConfirmEditModal } from "./service/confirmEditModal";
import { CategoryHandbook } from "../handbooks/categoryHandbook";
import { LocationHandbook } from "../handbooks/locationHandbook";
import { LockHandbook } from "../handbooks/lockHandbook";
import { LockSystemHandbook } from "../handbooks/lockSystemHandbook";
import { OfficeHandbook } from "../handbooks/officeHandbook";
import { OfficeTypeHandbook } from "../handbooks/officeTypeHandbook";
import { RenterHandbook } from "../handbooks/renterHandbook";
import { UserHandbook } from "../handbooks/userHandbook";
import { UserRoleHandbook } from "../handbooks/userRoleHandbook";

export type InputType = "text" | "password" | "email" | "datetime-local" | "office-type" |
  "user" | "renter" | "office" | "lock-system" | "lock" | "user-role" | "location" | "category" | "boolean";

export interface InputField {
  type: InputType;
  name: string;
  label: string;
  placeholder: string;
  startContent?: React.ReactNode;
  endContent?: React.ReactNode;
  isRequired?: boolean;
}

export interface DefaultValues {
  [key: string]: any;
}

export interface ModalError {
  field: string;
  message: string;
}

export interface ModalBuilderProps {
  title: string;
  fields: InputField[];
  defaultValues?: DefaultValues;
  isOpen: boolean;
  isSensitiveData: boolean;
  submitButtonText?: string;
  deleteButtonText?: string;
  modalErrors?: ModalError[];
  onSubmit: (data: any) => Promise<Boolean>;
  onDelete?: () => Promise<Boolean>;
  onOpenChange: (open: boolean) => void;
}

export function ModalBuilder({
  title, fields, defaultValues, isOpen, isSensitiveData,
  submitButtonText = "Создать", deleteButtonText = "Удалить", modalErrors = [], onSubmit,
  onDelete, onOpenChange,
}: ModalBuilderProps) {
  const { register, handleSubmit, formState: { errors }, setValue, control, setError, clearErrors } = useForm({ defaultValues });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);

  const editSensitiveDisclosure = useDisclosure();
  const deleteSensitiveDisclosure = useDisclosure();

  useEffect(() => {
    modalErrors.forEach(modalError => {
      setError(modalError.field, { type: "manual", message: modalError.message });
    });
  }, [modalErrors, clearErrors, setError]);

  useEffect(() => {
    if (!isOpen) {
      setLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (defaultValues) {
      Object.keys(defaultValues).forEach((key) => {
        setValue(key, defaultValues[key]);
      });
    }
  }, [defaultValues, setValue]);

  const submit = (data: any) => {
    if (isSensitiveData) {
      setData(data);
      editSensitiveDisclosure.onOpen();
    } else {
      onSensitiveSubmit(data);
    }
  };

  const del = () => {
    if (isSensitiveData) {
      deleteSensitiveDisclosure.onOpen();
    } else {
      onSensitiveDelete();
    }
  };

  const onSensitiveSubmit = (data: any) => {
    setLoading(true);
    onSubmit(data).then(_result => {
      setLoading(false);
    });
  }

  const onSensitiveDelete = () => {
    if (onDelete) {
      setLoading(true);
      onDelete().then(_result => {
        setLoading(false);
      });
    }
  }

  const getRegisterProps = (field: InputField) => {
    const props: RegisterOptions = {};

    if (field.isRequired) {
      props.required = "Это поле обязательно для заполнения";
    }

    return props;
  }

  const getBaseInputProps = (field: InputField) => {
    const props: any = Object.assign({}, register(field.name, getRegisterProps(field)));

    props.type = field.type;
    props.name = field.name;
    props.isInvalid = !!errors[field.name];
    props.errorMessage = errors[field.name]?.message;
    props.placeholder = field.placeholder;
    props.label = field.label;
    props.defaultValue = String(defaultValues?.[field.name] || "");
    props.isRequired = field.isRequired;

    return props;
  }

  const getHandbookProps = (field: InputField) => {
    const props: any = {};

    props.register = register(field.name, getRegisterProps(field));
    props.isInvalid = !!errors[field.name];
    props.errorMessage = errors[field.name]?.message?.toString() || "";
    props.defaultSelectedKey = String(defaultValues?.[field.name] || "");

    return props;
  }

  const renderInput = (field: InputField) => {
    switch (field.type) {
      case 'text':
      case 'email':
      case 'datetime-local':
      case 'password':
        return (
          <Input
            {...getBaseInputProps(field)}
            variant="bordered"
          />
        );

      case 'boolean':
        return (
          <Controller
            control={control}
            name={field.name}
            render={({ field: controllerField }) => (
              <Checkbox
                {...controllerField}
                isSelected={controllerField.value}
                onChange={controllerField.onChange}
                isInvalid={!!errors[field.name]}
              >
                {field.label}
              </Checkbox>
            )}
          />
        );

      case 'office':
        return <OfficeHandbook {...getHandbookProps(field)} />;

      case 'office-type':
        return <OfficeTypeHandbook {...getHandbookProps(field)} />;

      case 'renter':
        return <RenterHandbook {...getHandbookProps(field)} />;

      case 'user':
        return <UserHandbook {...getHandbookProps(field)} />;

      case 'lock-system':
        return <LockSystemHandbook {...getHandbookProps(field)} />;

      case 'lock':
        return <LockHandbook {...getHandbookProps(field)} />;

      case 'user-role':
        return <UserRoleHandbook {...getHandbookProps(field)} />;

      case 'location':
        return <LocationHandbook {...getHandbookProps(field)} />;

      case 'category':
        return <CategoryHandbook {...getHandbookProps(field)} />;
    }
  };

  return (
    <>
      <ConfirmEditModal isOpen={editSensitiveDisclosure.isOpen} onOpenChange={editSensitiveDisclosure.onOpenChange} callback={onSensitiveSubmit} data={data} />
      <ConfirmDeleteModal isOpen={deleteSensitiveDisclosure.isOpen} onOpenChange={deleteSensitiveDisclosure.onOpenChange} callback={onSensitiveDelete} />

      <Modal isOpen={isOpen} onOpenChange={onOpenChange} portalContainer={document.getElementById("main")!} backdrop="blur" scrollBehavior="outside">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>{title}</ModalHeader>
              <form onSubmit={handleSubmit(submit)}>
                <ModalBody>
                  {fields.map((field, index) => (
                    <div key={index} className="mb-4 block">
                      {renderInput(field)}
                    </div>
                  ))}
                </ModalBody>
                <ModalFooter>
                  <div className="flex flex-grow flex-row gap-2">
                    <Button color="default" onClick={onClose} variant="flat">Закрыть</Button>
                  </div>
                  {onDelete && (
                    <Button color="danger" onClick={del} variant="flat">{deleteButtonText}</Button>
                  )}
                  <Button color="success" type="submit" variant="flat" isLoading={loading}>{submitButtonText}</Button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
