import { Button, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ColumnType, TableBuilder } from "../../../components/tables/tableBuilder";
import { FaArrowRightToBracket, FaExpand } from "react-icons/fa6";
import { api } from "../../../../api";
import { useNavigate } from "react-router-dom";
import useErrorHandling from "../../../../hooks/useErrorHandling";
import { Renter } from "../../../../api/renters";

export function RentersUsersPage() {
  const [renters, setRenters] = useState<Renter[] | null>(null);
  const handleError = useErrorHandling();
  const fullscreenDisclosure = useDisclosure();

  const navigate = useNavigate();

  useEffect(() => {
    api.renters.list()
      .then(response => {
        setRenters(response.data);
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, []);

  return (
    <>
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
          <div className="flex flex-row justify-between w-full items-center mb-4">
            <div className="flex flex-row gap-4 items-center">
              <span className="font-medium">Арендаторы</span>
              <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}><FaExpand /></Button>
            </div>
          </div>
          <div className="flex flex-col gap-2 mb-2">
            <TableBuilder
              displayOptions={false}
              removeWrapper={true}
              isFullScreen={true}
              columns={[
                {
                  label: "Название арендатора",
                  key: "display_name",
                  type: ColumnType.String
                },
                {
                  label: "ОГРН",
                  key: "orgn",
                  type: ColumnType.String
                },
                {
                  label: "Владелец",
                  key: "holder",
                  type: ColumnType.User
                },
                {
                  label: "Номер телефона",
                  key: "phone_number",
                  type: ColumnType.String
                },
                {
                  label: "Email",
                  key: "email",
                  type: ColumnType.String
                },
                {
                  label: "Действия",
                  key: "actions",
                  type: ColumnType.Actions,
                  actions: [
                    {
                      icon: <FaArrowRightToBracket />,
                      onClick(_value, row) {
                        navigate("/dashboard/renters/" + row.id);
                      },
                    }
                  ]
                }
              ]}
              data={renters || []}
            />
          </div>
        </div>
      </div>
    </>
  );
}
