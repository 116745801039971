import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Layout } from "./layout";
import { DashboardLoader, IndexLoader, AuthLoader } from "./loaders";
import { LoginPage } from "./pages";
import { SelectProfile } from "./pages/dashboard/selectProfile";
import { DashboardDemo } from "./pages/dashboard/dashboard";
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from "react-redux";
import rootStore from "./store";
import { LocationPage } from "./pages/dashboard/location/[id]";
import { OfficePage } from "./pages/dashboard/location/office/[id]";
import { LocksPage } from "./pages/dashboard/locks";
import { LockPage } from "./pages/dashboard/locks/[id]";
import { LockSystemsPage } from "./pages/dashboard/lockSystems";
import { UserRolePage } from "./pages/dashboard/userRoles";
import { OfficeTypesPage } from "./pages/dashboard/officeTypes";
import { RentersUsersPage } from "./pages/dashboard/renters/renters";
import { SystemParamsPage } from "./pages/dashboard/system";
import { BillingPage } from "./pages/dashboard/payments";
import { RentedOfficesPage } from "./pages/dashboard/rentedOffices";
import { OfficesPage } from "./pages/dashboard/offices";
import { LocationsPage } from "./pages/dashboard/locations";
import { TicketsPage } from "./pages/dashboard/tickets";
import { TicketPage } from "./pages/dashboard/ticket/[id]";
import { UserViewPage } from "./components/pages/userPage";
import { ViewLocationPage } from "./components/pages/locationPage";
import { ViewBillingPage } from "./components/pages/paymentsPage";
import { ViewRentedOfficePage } from "./components/pages/rentedOfficePage";
import { ViewOfficePage } from "./components/pages/officePage";
import { ViewLockPage } from "./components/pages/lockPage";
import { SharedLocksPage } from "./pages/dashboard/sharedLocks";
import { ViewSharedLockPage } from "./components/pages/sharedLockPage";
import { SignUpPage } from "./pages/signUp";
import { BaseLayout } from "./layout/baseLayout";
import { InviteHashPage } from "./pages/invite";
import { DocumentTemplatesPage } from "./pages/dashboard/documents";
import { DocumentTemplatePage } from "./pages/dashboard/documents/template";
import { SettingsPage } from "./pages/dashboard/settings";
import { UsersPage } from "./pages/dashboard/users/users";
import { UserRolesEditPage } from "./pages/dashboard/users/[id]/roles";
import { RenterUsersPage } from "./pages/dashboard/renters/[id]/[id]";
import { RenterEmployeesPage } from "./pages/dashboard/renters/[id]/employees";
import { RenterOfficesPage } from "./pages/dashboard/renters/[id]/offices";
import { MyProfilePage } from "./pages/dashboard/me";
import { UserProfilePage } from "./pages/dashboard/users/[id]/[id]";

const router = createBrowserRouter([
  {
    path: "/",
    element: <BaseLayout />,
    children: [
      { path: "/",
        loader: AuthLoader,
        element: <LoginPage />
      },
      { path: "/signup",
        loader: AuthLoader,
        element: <SignUpPage />
      },
      {
        path: "/invite/:hash",
        element: <InviteHashPage />
      }
    ]
  },
  {
    path: "/dashboard",
    element: <Layout />,
    loader: DashboardLoader,
    children: [
      { path: "/dashboard", loader: IndexLoader, element: <DashboardDemo /> },
      { path: "/dashboard/selectProfile", loader: IndexLoader, element: <SelectProfile /> },
    
      { path: "/dashboard/location/:id", loader: IndexLoader, element: <LocationPage /> },
      { path: "/dashboard/location/:id/office/:officeId", loader: IndexLoader, element: <OfficePage /> },
      { path: "/dashboard/location/:id/view", loader: IndexLoader, element: <ViewLocationPage /> },
    
      { path: "/dashboard/locks", loader: IndexLoader, element: <LocksPage /> },
      { path: "/dashboard/locks/:id", loader: IndexLoader, element: <LockPage /> },
      { path: "/dashboard/locks/:id/view", loader: IndexLoader, element: <ViewLockPage /> },

      { path: "/dashboard/sharedLocks", loader: IndexLoader, element: <SharedLocksPage /> },
      { path: "/dashboard/sharedLocks/:id/view", loader: IndexLoader, element: <ViewSharedLockPage /> },
    
      { path: "/dashboard/tickets", loader: IndexLoader, element: <TicketsPage /> },
      { path: "/dashboard/tickets/:id", loader: IndexLoader, element: <TicketPage /> },
    
      { path: "/dashboard/renters", loader: IndexLoader, element: <RentersUsersPage /> },
      { path: "/dashboard/renters/:id", loader: IndexLoader, element: <RenterUsersPage /> },
      { path: "/dashboard/renters/:id/employees", loader: IndexLoader, element: <RenterEmployeesPage /> },
      { path: "/dashboard/renters/:id/offices", loader: IndexLoader, element: <RenterOfficesPage /> },
    
      { path: "/dashboard/system", loader: IndexLoader, element: <SystemParamsPage /> },
    
      { path: "/dashboard/officeTypes", loader: IndexLoader, element: <OfficeTypesPage /> },
    
      { path: "/dashboard/userRoles", loader: IndexLoader, element: <UserRolePage /> },
    
      { path: "/dashboard/lockSystems", loader: IndexLoader, element: <LockSystemsPage /> },
    
      { path: "/dashboard/users", loader: IndexLoader, element: <UsersPage /> },
      { path: "/dashboard/users/:id", loader: IndexLoader, element: <UserProfilePage /> },
      { path: "/dashboard/users/:id/view", loader: IndexLoader, element: <UserViewPage /> },
      { path: "/dashboard/users/:id/roles", loader: IndexLoader, element: <UserRolesEditPage /> },

      { path: "/dashboard/payments", loader: IndexLoader, element: <BillingPage /> },
      { path: "/dashboard/payments/:id/view", loader: IndexLoader, element: <ViewBillingPage /> },

      { path: "/dashboard/rentedOffices", loader: IndexLoader, element: <RentedOfficesPage /> },
      { path: "/dashboard/rentedOffices/:id/view", loader: IndexLoader, element: <ViewRentedOfficePage /> },
    
      { path: "/dashboard/offices", loader: IndexLoader, element: <OfficesPage /> },
      { path: "/dashboard/offices/:id/view", loader: IndexLoader, element: <ViewOfficePage /> },
    
      { path: "/dashboard/locations", loader: IndexLoader, element: <LocationsPage /> },
      { path: "/dashboard/locations/:id/view", loader: IndexLoader, element: <ViewLocationPage /> },

      { path: "/dashboard/documents", loader: IndexLoader, element: <DocumentTemplatesPage /> },
      { path: "/dashboard/documents/:id/fill", loader: IndexLoader, element: <DocumentTemplatePage /> },

      { path: "/dashboard/me", loader: IndexLoader, element: <MyProfilePage /> },
      { path: "/dashboard/settings", loader: IndexLoader, element: <SettingsPage /> },
    ]
  }
]);
 
export function App() {
  return (
    <Provider store={rootStore}>
      <RouterProvider router={router} />
    </Provider>
  )
}