import { Button, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { RentedOffice } from "../../../api/rentedOffices";
import { toast } from "react-toastify";
import { ColumnType, TableBuilder } from "../../components/tables/tableBuilder";
import { FaExpand, FaMagnifyingGlass, FaPencil, FaPlus } from "react-icons/fa6";
import { api } from "../../../api";
import { CreateRentedOfficeModal } from "../../components/modals/office/createRentedOfficeModal";
import { EditRentedOfficeModal } from "../../components/modals/office/editRentedOfficeModal";
// import { useNavigate } from "react-router-dom";
import useErrorHandling from "../../../hooks/useErrorHandling";
import { CreateRentedOfficeByOgrnModal } from "../../components/modals/office/createRentedOfficeByOgrnModal";

export function RentedOfficesPage() {
  const [offices, setOffices] = useState<RentedOffice[] | null>(null);
  const [office, setOffice] = useState<RentedOffice | null>(null);
  // const navigate = useNavigate();

  const handleError = useErrorHandling();

  const createRentedOfficeModalDisclosure = useDisclosure();
  const createRentedOfficeByOgrn = useDisclosure();
  const editRentedOfficeModalDisclosure = useDisclosure();

  const fullscreenDisclosure = useDisclosure();

  // const viewRentedOffice = (office: RentedOffice) => {
  //   navigate(`/dashboard/rentedOffices/${office.id}/view`);
  // };

  const editRentedOffice = (office: RentedOffice) => {
    setOffice(office);
    editRentedOfficeModalDisclosure.onOpen();
  };

  useEffect(() => {
    api.rentedOffices.list()
      .then(async response => {
        setOffices(response.data);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    createRentedOfficeModalDisclosure.isOpen,
    editRentedOfficeModalDisclosure.isOpen,
    createRentedOfficeByOgrn.isOpen
  ]);

  return (
    <>
      <CreateRentedOfficeModal disclosure={createRentedOfficeModalDisclosure} />
      <CreateRentedOfficeByOgrnModal disclosure={createRentedOfficeByOgrn} />
      <EditRentedOfficeModal disclosure={editRentedOfficeModalDisclosure} office={office} />
      <div className="p-4">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-bold text-2xl">Арендованные офисы</span>
            <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}><FaExpand /></Button>
          </div>
          <div className="flex flex-row gap-4 items-center">
            <Button
              startContent={<FaMagnifyingGlass />}
              color="primary"
              variant="bordered"
              className="max-w-fit"
              onClick={() => createRentedOfficeByOgrn.onOpen()}
            >
              Создать по ОГРН/ОГРНИП
            </Button>
            <Button
              startContent={<FaPlus />}
              color="primary"
              variant="solid"
              className="max-w-fit"
              onClick={() => createRentedOfficeModalDisclosure.onOpen()}
            >
              Создать
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            fullscreenDisclosure={fullscreenDisclosure}
            columns={[
              {
                label: "Офис",
                key: "office",
                type: ColumnType.Office,
                sortable: true
              },
              {
                label: "Арендатор",
                key: "renter",
                type: ColumnType.Renter,
                sortable: true
              },
              {
                label: "Цена",
                key: "price",
                type: ColumnType.Number,
                sortable: true
              },
              {
                label: "Описание",
                key: "description",
                type: ColumnType.String,
                sortable: true
              },
              {
                label: "Дата начала",
                key: "start_date",
                type: ColumnType.Date,
                sortable: true
              },
              {
                label: "Дата окончания",
                key: "end_date",
                type: ColumnType.Date,
                sortable: true
              },
              {
                key: "actions",
                label: "Действия",
                type: ColumnType.Actions,
                actions: [
                  // {
                  //   icon: <FaEye />,
                  //   onClick: (_value, row) => { viewRentedOffice(row) }
                  // },
                  {
                    icon: <FaPencil />,
                    onClick: (_value, row) => { editRentedOffice(row) }
                  }
                ]
              }
            ]}
            data={offices || []}
            rowsPerPage={10}
          />
        </div>
      </div>
    </>
  );
}
