import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { toast } from "react-toastify";
import { api } from "../../../../api";
import { Billing } from "../../../../api/billing";
import { getIdFromRenterHandbook } from "../../../../utils/getIdFromHandbooks";
import useErrorHandling from "../../../../hooks/useErrorHandling";
import { useState } from "react";

interface IBillingEditForm {
  number: number;
  date: string;
  payer: number;
  provider: number;
  amount: number;
  paid: boolean;
  description: string;
}

const editBillingFields: InputField[] = [
  { label: "Номер", name: "number", placeholder: "Номер", type: "text", isRequired: true },
  { label: "Дата", name: "date", placeholder: "Дата", type: "datetime-local", isRequired: true },
  { label: "Плательщик", name: "payer", placeholder: "Плательщик", type: "renter", isRequired: true },
  { label: "Поставщик", name: "provider", placeholder: "Поставщик", type: "text", isRequired: true },
  { label: "Сумма", name: "amount", placeholder: "Сумма", type: "text", isRequired: true },
  { label: "Оплачено", name: "paid", placeholder: "Оплачено", type: "text", isRequired: true },
  { label: "Описание", name: "description", placeholder: "Описание", type: "text", isRequired: true },
];

export function EditBillModal({ disclosure, billing }: { disclosure: ReturnType<typeof useDisclosure>, billing: Billing | null }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);
  
  const handleError = useErrorHandling();

  const onSubmit = (data: IBillingEditForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const payer = await getIdFromRenterHandbook(String(data.payer));

      api.billings.update(billing!.id, {
          number: data.number,
          date: data.date,
          payer: Number(payer),
          provider: data.provider,
          amount: data.amount,
          paid: data.paid,
          description: data.description,
        })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  }

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.billings.delete(billing!.id)
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder
      title="Редактирование платежа"
      fields={editBillingFields}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      modalErrors={modalErrors}
      onSubmit={onSubmit}
      onDelete={onDelete}
      isSensitiveData={true}
      submitButtonText="Сохранить"
      defaultValues={{
        number: billing?.number,
        date: billing?.date,
        payer: billing?.payer,
        provider: billing?.provider,
        amount: billing?.amount,
        paid: billing?.paid,
        description: billing?.description
      }} />
  );
}
