import rootStore from "../../app/store";
import crud, { ApiResponse } from "../crud"

export interface Office {
  id: number;
  display_name: string;
  office_type: number;
  area: number;
  price: number;
  room_count: number;
  location: number;
  floor: number;
  office_lock: number;
}

export interface OfficeCreate {
  display_name: string;
  office_type: number;
  area: number;
  price: number;
  room_count: number;
  location: number;
  floor: number;
  office_lock: number;
}

export interface OfficeUpdate {
  display_name: string;
  office_type: number;
  area: number;
  price: number;
  room_count: number;
  location: number;
  floor: number;
  office_lock: number;
}

export function OfficesAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/offices/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Office[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/offices/${id}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Office>;
    },
    create: async (officeData: OfficeCreate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/offices/?business_profile_id=${businessProfileId}`, officeData, true) as ApiResponse<Office>;
    },
    update: async (id: number, officeData: OfficeUpdate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/admin/offices/${id}?business_profile_id=${businessProfileId}`, officeData, true) as ApiResponse<{}>;
    },
    delete: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/admin/offices/${id}?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    }
  }
}
