import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "../../../../api";
import { toast } from "react-toastify";
import { getIdFromLocationHandbook, getIdFromLockHandbook, getIdFromOfficeTypeHandbook } from "../../../../utils/getIdFromHandbooks";
import useErrorHandling from "../../../../hooks/useErrorHandling";
import { useState } from "react";

interface ICreateOfficeForm {
  display_name: string;
  office_type: number;
  area: number;
  price: number;
  room_count: number;
  floor: number;
  office_lock: number;
  location: number;
}

const createOfficeFields: InputField[] = [
  {
    label: "Название офиса",
    name: "display_name",
    placeholder: "Офис 1",
    type: "text",
    isRequired: true
  }, {
    label: "Тип офиса",
    name: "office_type",
    placeholder: "тут будет справочник, сейчас не нужно",
    type: "office-type",
    isRequired: true
  }, {
    label: "Площадь",
    name: "area",
    placeholder: "10м",
    type: "text",
    isRequired: true
  }, {
    label: "Стоимость",
    name: "price",
    placeholder: "90000",
    type: "text",
    isRequired: true
  }, {
    label: "Количество комнат",
    name: "room_count",
    placeholder: "2",
    type: "text",
    isRequired: true
  }, {
    label: "Этаж",
    name: "floor",
    placeholder: "5",
    type: "text",
    isRequired: true
  }, {
    label: "Замок",
    name: "office_lock",
    placeholder: "",
    type: "lock",
    isRequired: true
  }, {
    label: "Локация",
    name: "location",
    placeholder: "",
    type: "location",
    isRequired: true
  }
]

export function CreateOfficeModal({ disclosure }: { disclosure: ReturnType<typeof useDisclosure> }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);

  const handleError = useErrorHandling();

  const onSubmit = (data: ICreateOfficeForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const officeType = await getIdFromOfficeTypeHandbook(String(data.office_type));
      const lock = await getIdFromLockHandbook(String(data.office_lock));
      const location = await getIdFromLocationHandbook(String(data.location));

      api.offices.create({
        display_name: data.display_name,
        office_type: Number(officeType),
        area: data.area,
        floor: data.floor,
        office_lock: Number(lock),
        price: data.price,
        room_count: data.room_count,
        location: Number(location)
      })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder title="Создание офиса" modalErrors={modalErrors} fields={createOfficeFields} isOpen={isOpen} isSensitiveData={false} onOpenChange={onOpenChange} onSubmit={onSubmit} />
  );
}