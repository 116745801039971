import { Button, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { api } from "../../../api";
import { toast } from "react-toastify";
import { ColumnType, TableBuilder } from "../../components/tables/tableBuilder";
import { FaExpand, FaPencil, FaPlus } from "react-icons/fa6";
import useErrorHandling from "../../../hooks/useErrorHandling";
import { SharedLock } from "../../../api/sharedLocks";
import { EditSharedLockModal } from "../../components/modals/lock/editSharedLockModal";
import { CreateSharedLockModal } from "../../components/modals/lock/createSharedLockModal";
// import { useNavigate } from "react-router-dom";

export function SharedLocksPage() {
  const [locks, setLocks] = useState<SharedLock[] | null>(null);
  const [lock, setLock] = useState<SharedLock | null>(null);

  // const navigate = useNavigate();

  const handleError = useErrorHandling();
  
  const createSharedLockModalDisclosure = useDisclosure();
  const editSharedLockModalDisclosure = useDisclosure();

  const fullscreenDisclosure = useDisclosure();

  // const viewSharedLock = (lock: SharedLock) => {
  //   navigate(`/dashboard/lock/${lock.id}/view`);
  // };

  const editLock = (lock: SharedLock) => {
    setLock(lock);
    editSharedLockModalDisclosure.onOpen();
  }

  useEffect(() => {
    api.sharedLocks.list()
      .then(response => {
        setLocks(response.data)
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    createSharedLockModalDisclosure.isOpen,
    editSharedLockModalDisclosure.isOpen
  ])

  return (
    <>
      <CreateSharedLockModal disclosure={createSharedLockModalDisclosure} />
      <EditSharedLockModal disclosure={editSharedLockModalDisclosure} lock={lock} />
      <div className="p-4">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-bold text-2xl">Замки</span>
            <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}><FaExpand /></Button>
          </div>
          <div className="flex flex-row gap-4 items-center">
            <Button startContent={<FaPlus />} color="primary" variant="solid" className="max-w-fit" onClick={() => createSharedLockModalDisclosure.onOpen()}>Создать общий замок</Button>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            fullscreenDisclosure={fullscreenDisclosure}
            columns={[
              {
                label: "Системный номер",
                key: "id",
                type: ColumnType.String,
                sortable: true
              },
              {
                label: "Замок",
                key: "lock_id",
                type: ColumnType.Lock,
                sortable: true
              },
              {
                label: "Локация",
                key: "location_id",
                type: ColumnType.Location,
                sortable: true
              },
              {
                key: "actions",
                label: "Действия",
                type: ColumnType.Actions,
                actions: [
                  {
                    icon: <FaPencil />,
                    onClick: (_value, row) => { editLock(row) }
                  }
                ]
              }              
            ]}
            data={locks || []}
            rowsPerPage={10}
          />
        </div>
      </div>
    </>
  );
}