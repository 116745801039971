import { Button, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { CreateBillModal } from "../../components/modals/bill/createBillModal";
import { EditBillModal } from "../../components/modals/bill/editBillModal";
import { toast } from "react-toastify";
import { ColumnType, TableBuilder } from "../../components/tables/tableBuilder";
import { FaExpand, FaPencil, FaPlus } from "react-icons/fa6";
import { api } from "../../../api";
import { Billing } from "../../../api/billing";
// import { useNavigate } from "react-router-dom";
import useErrorHandling from "../../../hooks/useErrorHandling";

export function BillingPage() {
  const [billings, setBillings] = useState<Billing[] | null>(null);
  const [billing, setBilling] = useState<Billing | null>(null);

  const handleError = useErrorHandling();

  const createBillingModalDisclosure = useDisclosure();
  const editBillingModalDisclosure = useDisclosure();
  // const navigate = useNavigate();

  const fullscreenDisclosure = useDisclosure();

  const editBilling = (billing: Billing) => {
    setBilling(billing);
    editBillingModalDisclosure.onOpen();
  };

  // const viewBilling = (billing: Billing) => {
  //   navigate(`/dashboard/billings/${billing.id}/view`);
  // };

  useEffect(() => {
    api.billings.list()
      .then(response => {
        setBillings(response.data);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    createBillingModalDisclosure.isOpen,
    editBillingModalDisclosure.isOpen
  ]);

  return (
    <>
      <CreateBillModal disclosure={createBillingModalDisclosure} />
      <EditBillModal disclosure={editBillingModalDisclosure} billing={billing} />
      <div className="p-4">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-bold text-2xl">Биллинг</span>
            <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}><FaExpand /></Button>
          </div>
          <div className="flex flex-row gap-4 items-center">
            <Button
              startContent={<FaPlus />}
              color="primary"
              variant="solid"
              className="max-w-fit"
              onClick={() => createBillingModalDisclosure.onOpen()}
            >
              Создать биллинг
            </Button>
          </div>
          </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            fullscreenDisclosure={fullscreenDisclosure}
            columns={[
              {
                label: "Номер",
                key: "number",
                type: ColumnType.Number,
                sortable: true
              },
              {
                label: "Дата",
                key: "date",
                type: ColumnType.Date,
                sortable: true
              },
              {
                label: "Плательщик",
                key: "payer",
                type: ColumnType.Renter,
                sortable: true
              },
              {
                label: "Поставщик",
                key: "provider",
                type: ColumnType.String,
                sortable: true
              },
              {
                label: "Сумма",
                key: "amount",
                type: ColumnType.Number,
                sortable: true
              },
              {
                label: "Оплачено",
                key: "paid",
                type: ColumnType.Boolean,
                sortable: true
              },
              {
                label: "Описание",
                key: "description",
                type: ColumnType.String,
                sortable: true
              },
              {
                key: "actions",
                label: "Действия",
                type: ColumnType.Actions,
                actions: [
                  // {
                  //   icon: <FaEye />,
                  //   onClick: (_value, row) => { viewBilling(row) }
                  // },
                  {
                    icon: <FaPencil />,
                    onClick: (_value, row) => { editBilling(row) }
                  }
                ]
              }              
            ]}
            data={billings || []}
            rowsPerPage={10}
          />
        </div>
      </div>
    </>
  );
}
