import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OfficeLocation } from "../../../api/locations";
import { Lock } from "../../../api/locks";
import { LockSystem } from "../../../api/lockSystems/lockSystems";
import { Office } from "../../../api/offices";
import { OfficeType } from "../../../api/officeTypes";
import { Renter } from "../../../api/renters";
import { TicketCategory } from "../../../api/tickets/tickets";
import { UserRole } from "../../../api/userRoles/userRoles";
import { User } from "../../../api/users";

type TicketCategoryStore = TicketCategory[];
type LocationStore = OfficeLocation[];
type LockStore = Lock[];
type LockSystemStore = LockSystem[];
type OfficeStore = Office[];
type OfficeTypeStore = OfficeType[];
type RenterStore = Renter[];
type UserStore = User[];
type UserRoleStore = UserRole[];

interface HandbooksStoreState {
  ticketCategory: TicketCategoryStore,
  location: LocationStore,
  lock: LockStore,
  lockSystem: LockSystemStore,
  office: OfficeStore,
  officeType: OfficeTypeStore,
  renter: RenterStore,
  user: UserStore,
  userRole: UserRoleStore
};

const initialState: HandbooksStoreState = {
  ticketCategory: [],
  location: [],
  lock: [],
  lockSystem: [],
  office: [],
  officeType: [],
  renter: [],
  user: [],
  userRole: []
};

const handbooksSlice = createSlice({
  name: 'handbooks',
  initialState,
  reducers: {
    setTicketCategories(state, action: PayloadAction<TicketCategoryStore>) {
      state.ticketCategory = action.payload;
    },
    setLocations(state, action: PayloadAction<LocationStore>) {
      state.location = action.payload;
    },
    setLocks(state, action: PayloadAction<LockStore>) {
      state.lock = action.payload;
    },
    setLockSystems(state, action: PayloadAction<LockSystemStore>) {
      state.lockSystem = action.payload;
    },
    setOffices(state, action: PayloadAction<OfficeStore>) {
      state.office = action.payload;
    },
    setOfficeTypes(state, action: PayloadAction<OfficeTypeStore>) {
      state.officeType = action.payload;
    },
    setRenters(state, action: PayloadAction<RenterStore>) {
      state.renter = action.payload;
    },
    setUsers(state, action: PayloadAction<UserStore>) {
      state.user = action.payload;
    },
    setUserRoles(state, action: PayloadAction<UserRoleStore>) {
      state.userRole = action.payload;
    }
  }
});

export const {
  setTicketCategories,
  setLocations,
  setLocks,
  setLockSystems,
  setOffices,
  setOfficeTypes,
  setRenters,
  setUsers,
  setUserRoles
} = handbooksSlice.actions;

export default handbooksSlice.reducer;