import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from "@nextui-org/react";
import { LockSystem } from "../../../../api/lockSystems/lockSystems";
import { ColumnType, TableBuilder } from "../../tables/tableBuilder";
import { EditLockSystemModal } from "../../modals/lock/editLockSystemModal";

export function LockSystemModal({ isOpen, onOpenChange, item }: { isOpen: boolean, onOpenChange: (open: boolean) => void, item: LockSystem }) {
  const editDisclosure = useDisclosure();

  return (
    <>
      <EditLockSystemModal disclosure={editDisclosure} lockSystem={item} />
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} portalContainer={document.getElementById("main")!} backdrop="blur" scrollBehavior="outside">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Просмотр системы замка</ModalHeader>
              <ModalBody>
                <div className="flex flex-col gap-1 mb-2">
                  <TableBuilder
                    displayOptions={false}
                    displaySearch={false}
                    columns={[
                      {
                        key: "display_name",
                        label: "Название",
                        type: ColumnType.String
                      },
                      {
                        key: "api_key",
                        label: "Ключ API",
                        type: ColumnType.String
                      }
                    ]}
                    data={[item]}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button variant="flat" color="default" onClick={editDisclosure.onOpen}>Редактировать</Button>
                <Button variant="flat" color="default" onClick={onClose}>Закрыть</Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}