import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from "@nextui-org/react";
import { ColumnType, TableBuilder } from "../../tables/tableBuilder";
import { Office } from "../../../../api/offices";
import { EditOfficeWithLocationModal } from "../../modals/office/editOfficeWithLocaitonModal";
import { useEffect, useState } from "react";
import { api } from "../../../../api";
import { OfficeLocation } from "../../../../api/locations";
import { LocationChip } from "../locationChip";
import { OfficeTypeChip } from "../officeTypeChip";

export function OfficeModal({ isOpen, onOpenChange, item }: { isOpen: boolean, onOpenChange: (open: boolean) => void, item: Office | null }) {
  const editDisclosure = useDisclosure();
  const [location, setLocation] = useState<OfficeLocation | null>(null);

  useEffect(() => {
    api.locations.get(item?.location || 0)
      .then(data => {
        setLocation(data.data)
      })
      .catch(err => {
        console.error(err);
      })
  }, []);

  return (
    <>
      <EditOfficeWithLocationModal disclosure={editDisclosure} location={location} office={item} />
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} portalContainer={document.getElementById("main")!} backdrop="blur" scrollBehavior="outside" size="4xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Просмотр офиса</ModalHeader>
              <ModalBody>
                <div className="flex flex-col gap-1 mb-2">
                  <TableBuilder
                    displayOptions={false}
                    displaySearch={false}
                    columns={[
                      {
                        key: "display_name",
                        label: "Название",
                        type: ColumnType.String
                      },
                      {
                        key: "area",
                        label: "Площадь",
                        type: ColumnType.Number
                      },
                      {
                        key: "price",
                        label: "Цена",
                        type: ColumnType.Number
                      },
                      {
                        key: "floor",
                        label: "Этаж",
                        type: ColumnType.Number
                      },
                      {
                        key: "room_count",
                        label: "Комнаты",
                        type: ColumnType.Number
                      },
                      {
                        key: "office_type",
                        label: "Тип офиса",
                        render(value, _row) {
                          return <OfficeTypeChip officeTypeId={value} />
                        },
                        type: ColumnType.Custom
                      },
                      {
                        key: "location",
                        label: "Локация",
                        render(value, _row) {
                          return <LocationChip locationId={value} />
                        },
                        type: ColumnType.Custom
                      }
                    ]}
                    data={[item]}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button variant="flat" color="default" onClick={editDisclosure.onOpen}>Редактировать</Button>
                <Button variant="flat" color="default" onClick={onClose}>Закрыть</Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}