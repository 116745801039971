import { Button, Divider, useDisclosure } from "@nextui-org/react";
import { FaBuilding, FaCity, FaMapPin, FaPenToSquare, FaPlus, FaTrainSubway } from "react-icons/fa6";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useEffect, useState } from "react";
import { BusinessProfile } from "../../../api/businessProfiles";
import { api } from "../../../api";
import { toast } from "react-toastify";
import { EditBusinessProfileModal } from "../../components/modals/businessProfile/editBusinessProfileModal";
import { OfficeLocation } from "../../../api/locations";
import { CreateLocationModal } from "../../components/modals/location/createLocationModal";
import { Link } from "react-router-dom";
import useErrorHandling from "../../../hooks/useErrorHandling";
import { sortAlphabeticallyByKey } from "../../../utils";

export function DashboardDemo() {
  const [businessProfileData, setBusinessProfileData] = useState<BusinessProfile | null>(null);
  const [locations, setLocations] = useState<OfficeLocation[] | null>(null);

  const handleError = useErrorHandling();

  const businessProfile = useAppSelector(state => state.businessProfile.businessProfile);

  const editBusinessProfileModalDisclosure = useDisclosure();
  const createLocationModalDisclosure = useDisclosure();

  useEffect(() => {
    if(businessProfile.id == 0) return;
    
    api.businessProfiles.get(businessProfile.id)
      .then(response => {
        setBusinessProfileData(response.data);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
        localStorage.removeItem("businessProfileId");
      });

    api.businessProfiles.getLocations(businessProfile.id)
      .then(response => {
        const sortedData = sortAlphabeticallyByKey(response.data, "display_name");
        setLocations(sortedData);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    businessProfile,
    editBusinessProfileModalDisclosure.isOpen,
    createLocationModalDisclosure.isOpen
  ]);

  return (
    <>
      <CreateLocationModal disclosure={createLocationModalDisclosure} />
      <EditBusinessProfileModal disclosure={editBusinessProfileModalDisclosure} businessProfile={businessProfileData} />
      <div>
        {businessProfileData && (
          <div className="w-full h-full flex flex-col gap-2 p-8">
            {/* <div className="w-full min-h-[290px] bg-gray-500 bg-opacity-50 rounded-xl" /> */}
            <div className="flex flex-col gap-2 mb-2">
              <div className="flex flex-row gap-4 items-center mb-2">
                <span className="font-bold text-2xl">{businessProfileData.display_name}</span>
              </div>
              <div className="flex flex-col p-4 gap-3 rounded-xl bg-zinc-100 dark:bg-zinc-950 border-2 border-foreground border-opacity-10 max-w-[512px] mb-2">
                <span className="text-sm text-primary">{businessProfileData.legal_address}</span>
                { /* TODO */}
                <span className="text-sm text-foreground-500">Нужно обсудить, будем ли мы добавлять описание или просто забьём.</span>
                <Divider />
                <span className="text-sm text-foreground-500">ИНН: {businessProfileData.inn}</span>
                <span className="text-sm text-foreground-500">ОГРН: {businessProfileData.ogrn}</span>
              </div>
              <Button startContent={<FaPenToSquare />} size="md" color="primary" variant="solid" className="max-w-fit" onClick={() => editBusinessProfileModalDisclosure.onOpen()}>Редактировать</Button>
            </div>
            <Divider className="mt-2 mb-2" />
            <div className="flex flex-col gap-2 mb-2">
              <div className="flex flex-row gap-4 items-center mb-2">
                <span className="font-bold text-xl">Список локаций</span>
              </div>
              <Button startContent={<FaPlus />} color="primary" variant="solid" className="max-w-fit" onClick={() => createLocationModalDisclosure.onOpen()}>Создать</Button>
              {locations && locations.length > 0 && (
                <div className="flex flex-row flex-wrap gap-3 items-center mt-2">
                  {locations.map((location, index) => (
                    <Link to={`/dashboard/location/${location.id}`} className="flex flex-col bg-background border-2 border-default-200 rounded-xl cursor-pointer select-none" key={index}>
                      <div className="flex flex-col gap-3 p-4">
                        <span className="text-medium font-bold">{location.display_name}</span>
                        <div className="flex flex-row gap-2 items-center">
                          <FaCity />
                          <span className="text-sm">{location.city}</span>
                        </div>
                        <div className="flex flex-row gap-2 items-center">
                          <FaMapPin />
                          <span className="text-sm">{location.address}</span>
                        </div>
                        {location.district && (
                          <div className="flex flex-row gap-2 items-center">
                            <FaBuilding />
                            <span className="text-sm">{location.district}</span>
                          </div>
                        )}
                        {location.metro_station && (
                          <div className="flex flex-row gap-2 items-center">
                            <FaTrainSubway />
                            <span className="text-sm">{location.metro_station}</span>
                          </div>
                        )}
                      </div>
                    </Link>
                  ))}
                </div>
              )}
              {locations?.length == 0 && (
                <span>Похоже, в вашем бизнес-профиле ещё нет ни одной локации. Создайте первую!</span>
              )}
            </div>
            {/* <div className="flex flex-row flex-wrap g-3 p-4 items-center gap-2 max-w-5xl">
              <div className="flex flex-row gap-3 p-4 border-2 border-black border-opacity-10 rounded-xl items-center">
                <div className="flex flex-row gap-[10px] rounded-xl bg-primary bg-opacity-40 border-2 border-primary justify-center items-center w-12 h-12">
                  <FaPhone className="text-primary" />
                </div>
                <span className="text-primary font-medium">12</span>
                <span className="text-foreground-500">переговорок</span>
              </div>
              <div className="flex flex-row gap-3 p-4 border-2 border-black border-opacity-10 rounded-xl items-center">
                <div className="flex flex-row gap-[10px] rounded-xl bg-primary bg-opacity-40 border-2 border-primary justify-center items-center w-12 h-12">
                  <FaComputer className="text-primary" />
                </div>
                <span className="text-primary font-medium">21</span>
                <span className="text-foreground-500">рабочее место</span>
              </div>
              <div className="flex flex-row gap-3 p-4 border-2 border-black border-opacity-10 rounded-xl items-center">
                <div className="flex flex-row gap-[10px] rounded-xl bg-primary bg-opacity-40 border-2 border-primary justify-center items-center w-12 h-12">
                  <FaDoorOpen className="text-primary" />
                </div>
                <span className="text-primary font-medium">13</span>
                <span className="text-foreground-500">рабочих мест (open-space)</span>
              </div>
              <div className="flex flex-row gap-3 p-4 border-2 border-black border-opacity-10 rounded-xl items-center">
                <div className="flex flex-row gap-[10px] rounded-xl bg-primary bg-opacity-40 border-2 border-primary justify-center items-center w-12 h-12">
                  <FaUsers className="text-primary" />
                </div>
                <span className="text-primary font-medium">18</span>
                <span className="text-foreground-500">арендаторов</span>
              </div>
              <div className="flex flex-row gap-3 p-4 border-2 border-black border-opacity-10 rounded-xl items-center">
                <div className="flex flex-row gap-[10px] rounded-xl bg-primary bg-opacity-40 border-2 border-primary justify-center items-center w-12 h-12">
                  <FaGear className="text-primary" />
                </div>
                <span className="text-primary font-medium">54</span>
                <span className="text-foreground-500">пользователя</span>
              </div>
            </div>
            <span className="font-bold text-2xl">Последние платежи</span>
            <div className="gap-4 px-4 py-2 flex flex-row flex-wrap max-w-5xl items-center">
              <div className="flex flex-col gap-3 p-4 bg-background border-2 border-black border-opacity-10 rounded-xl max-w-52 min-w-52">
                <span className="text-xl font-semibold">110.000р</span>
                <span className="text-sm text-foreground text-opacity-50">Оплата аренды</span>
                <div className="flex flex-row gap-2 items-center text-primary">
                  <span className="text-sm font-semibold">Оплачено</span>
                  <span className="text-sm text-primary"><FaCheck /></span>
                </div>
              </div>
              <div className="flex flex-col gap-3 p-4 bg-background border-2 border-black border-opacity-10 rounded-xl max-w-52 min-w-52">
                <span className="text-xl font-semibold">110.000р</span>
                <span className="text-sm text-foreground text-opacity-50">Оплата аренды</span>
                <div className="flex flex-row gap-2 items-center text-primary">
                  <span className="text-sm font-semibold">Оплачено</span>
                  <span className="text-sm text-primary"><FaCheck /></span>
                </div>
              </div>
              <div className="flex flex-col gap-3 p-4 bg-background border-2 border-black border-opacity-10 rounded-xl max-w-52 min-w-52">
                <span className="text-xl font-semibold">110.000р</span>
                <span className="text-sm text-foreground text-opacity-50">Оплата аренды</span>
                <div className="flex flex-row gap-2 items-center text-primary">
                  <span className="text-sm font-semibold">Оплачено</span>
                  <span className="text-sm text-primary"><FaCheck /></span>
                </div>
              </div>
              <div className="flex flex-col gap-3 p-4 bg-background border-2 border-black border-opacity-10 rounded-xl max-w-52 min-w-52">
                <span className="text-xl font-semibold">110.000р</span>
                <span className="text-sm text-foreground text-opacity-50">Оплата аренды</span>
                <div className="flex flex-row gap-2 items-center text-primary">
                  <span className="text-sm font-semibold">Оплачено</span>
                  <span className="text-sm text-primary"><FaCheck /></span>
                </div>
              </div>
            </div>
            <Button size="md" color="primary" variant="solid" className="max-w-fit">Просмотреть все</Button>
            <span className="font-bold text-2xl">Арендаторы</span>
            <div className="flex flex-col gap-2 mt-2">
              <div className="flex flex-row gap-2 items-center">
                <span className="text-xl font-semibold">Компания 1</span>
                <span className="text-primary"><FaArrowRight /></span>
              </div>
              <div className="flex flex-row gap-4 p-4 items-center flex-wrap">
                <div className="flex flex-row bg-background rounded-xl items-center gap-1 cursor-pointer select-none">
                  <div className="solid w-16 h-16 bg-zinc-500 rounded-xl"></div>
                  <div className="flex flex-col gap-1 p-2">
                    <span className="text-base font-bold">Александр Соколов</span>
                    <div className="flex flex-row gap-1 items-center">
                      <span className="text-sm text-yellow-400"><FaCrown /></span>
                      <span className="text-sm">администратор</span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row bg-background rounded-xl items-center gap-1 cursor-pointer select-none">
                  <div className="solid w-16 h-16 bg-zinc-500 rounded-xl"></div>
                  <div className="flex flex-col gap-1 p-2">
                    <span className="text-base font-bold">Александр Соколов</span>
                    <div className="flex flex-row gap-1 items-center">
                      <span className="text-sm text-yellow-400"><FaCrown /></span>
                      <span className="text-sm">администратор</span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row bg-background rounded-xl items-center gap-1 cursor-pointer select-none">
                  <div className="solid w-16 h-16 bg-zinc-500 rounded-xl"></div>
                  <div className="flex flex-col gap-1 p-2">
                    <span className="text-base font-bold">Александр Соколов</span>
                    <div className="flex flex-row gap-1 items-center">
                      <span className="text-sm text-yellow-400"><FaCrown /></span>
                      <span className="text-sm">администратор</span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row bg-background rounded-xl items-center gap-1 cursor-pointer select-none">
                  <div className="solid w-16 h-16 bg-zinc-500 rounded-xl"></div>
                  <div className="flex flex-col gap-1 p-2">
                    <span className="text-base font-bold">Александр Соколов</span>
                    <div className="flex flex-row gap-1 items-center">
                      <span className="text-sm text-yellow-400"><FaCrown /></span>
                      <span className="text-sm">администратор</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <span className="text-xl font-semibold">Компания 2</span>
                <span className="text-primary"><FaArrowRight /></span>
              </div>
              <div className="flex flex-row gap-4 p-4 items-center flex-wrap">
                <div className="flex flex-row bg-background rounded-xl items-center gap-1 cursor-pointer select-none">
                  <div className="solid w-16 h-16 bg-zinc-500 rounded-xl"></div>
                  <div className="flex flex-col gap-1 p-2">
                    <span className="text-base font-bold">Александр Соколов</span>
                    <div className="flex flex-row gap-1 items-center">
                      <span className="text-sm text-yellow-400"><FaCrown /></span>
                      <span className="text-sm">администратор</span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row bg-background rounded-xl items-center gap-1 cursor-pointer select-none">
                  <div className="solid w-16 h-16 bg-zinc-500 rounded-xl"></div>
                  <div className="flex flex-col gap-1 p-2">
                    <span className="text-base font-bold">Александр Соколов</span>
                    <div className="flex flex-row gap-1 items-center">
                      <span className="text-sm text-yellow-400"><FaCrown /></span>
                      <span className="text-sm">администратор</span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row bg-background rounded-xl items-center gap-1 cursor-pointer select-none">
                  <div className="solid w-16 h-16 bg-zinc-500 rounded-xl"></div>
                  <div className="flex flex-col gap-1 p-2">
                    <span className="text-base font-bold">Александр Соколов</span>
                    <div className="flex flex-row gap-1 items-center">
                      <span className="text-sm text-yellow-400"><FaCrown /></span>
                      <span className="text-sm">администратор</span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row bg-background rounded-xl items-center gap-1 cursor-pointer select-none">
                  <div className="solid w-16 h-16 bg-zinc-500 rounded-xl"></div>
                  <div className="flex flex-col gap-1 p-2">
                    <span className="text-base font-bold">Александр Соколов</span>
                    <div className="flex flex-row gap-1 items-center">
                      <span className="text-sm text-yellow-400"><FaCrown /></span>
                      <span className="text-sm">администратор</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <span className="text-xl font-semibold">Компания 3</span>
                <span className="text-primary"><FaArrowRight /></span>
              </div>
              <div className="flex flex-row gap-4 p-4 items-center flex-wrap">
                <div className="flex flex-row bg-background rounded-xl items-center gap-1 cursor-pointer select-none">
                  <div className="solid w-16 h-16 bg-zinc-500 rounded-xl"></div>
                  <div className="flex flex-col gap-1 p-2">
                    <span className="text-base font-bold">Александр Соколов</span>
                    <div className="flex flex-row gap-1 items-center">
                      <span className="text-sm text-yellow-400"><FaCrown /></span>
                      <span className="text-sm">администратор</span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row bg-background rounded-xl items-center gap-1 cursor-pointer select-none">
                  <div className="solid w-16 h-16 bg-zinc-500 rounded-xl"></div>
                  <div className="flex flex-col gap-1 p-2">
                    <span className="text-base font-bold">Александр Соколов</span>
                    <div className="flex flex-row gap-1 items-center">
                      <span className="text-sm text-yellow-400"><FaCrown /></span>
                      <span className="text-sm">администратор</span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row bg-background rounded-xl items-center gap-1 cursor-pointer select-none">
                  <div className="solid w-16 h-16 bg-zinc-500 rounded-xl"></div>
                  <div className="flex flex-col gap-1 p-2">
                    <span className="text-base font-bold">Александр Соколов</span>
                    <div className="flex flex-row gap-1 items-center">
                      <span className="text-sm text-yellow-400"><FaCrown /></span>
                      <span className="text-sm">администратор</span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row bg-background rounded-xl items-center gap-1 cursor-pointer select-none">
                  <div className="solid w-16 h-16 bg-zinc-500 rounded-xl"></div>
                  <div className="flex flex-col gap-1 p-2">
                    <span className="text-base font-bold">Александр Соколов</span>
                    <div className="flex flex-row gap-1 items-center">
                      <span className="text-sm text-yellow-400"><FaCrown /></span>
                      <span className="text-sm">администратор</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span className="font-bold text-2xl">Быстрые действия</span>
            <div className="flex flex-col p-4 gap-3 rounded-xl bg-background border-2 border-foreground border-opacity-10">
              <span className="font-semibold">Тикеты</span>
            </div>
            <div className="flex flex-col p-4 gap-3 rounded-xl bg-background border-2 border-foreground border-opacity-10">
              <span className="font-semibold">Зарегистрировать пользователя</span>
            </div> */}
          </div>
        )}
        {!businessProfileData && (
          <span>Идёт загрузка данных, подождите</span>
        )}
      </div>
    </>
  )
}