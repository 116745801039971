import { Button, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { CreateLockModal } from "../../components/modals/lock/createLockModal";
import { api } from "../../../api";
import { Lock } from "../../../api/locks";
import { toast } from "react-toastify";
import { ColumnType, TableBuilder } from "../../components/tables/tableBuilder";
import { FaExpand, FaLock, FaPencil, FaPlus } from "react-icons/fa6";
import { EditLockModal } from "../../components/modals/lock/editLockModal";
import { useNavigate } from "react-router-dom";
import useErrorHandling from "../../../hooks/useErrorHandling";

export function LocksPage() {
  const [locks, setLocks] = useState<Lock[] | null>(null);
  const [lock, setLock] = useState<Lock | null>(null);
  const navigate = useNavigate();

  const handleError = useErrorHandling();
  
  const createLockModalDisclosure = useDisclosure();
  const editLockModalDisclosure = useDisclosure();

  const fullscreenDisclosure = useDisclosure();

  const editLock = (lock: Lock) => {
    setLock(lock);
    editLockModalDisclosure.onOpen();
  }

  useEffect(() => {
    api.locks.list()
      .then(response => {
        setLocks(response.data)
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    createLockModalDisclosure.isOpen,
    editLockModalDisclosure.isOpen
  ])

  return (
    <>
      <CreateLockModal disclosure={createLockModalDisclosure} />
      <EditLockModal disclosure={editLockModalDisclosure} lock={lock} />
      <div className="p-4">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-bold text-2xl">Замки</span>
            <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}><FaExpand /></Button>
          </div>
          <div className="flex flex-row gap-4 items-center">
            <Button startContent={<FaPlus />} color="primary" variant="solid" className="max-w-fit" onClick={() => createLockModalDisclosure.onOpen()}>Создать замок</Button>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            fullscreenDisclosure={fullscreenDisclosure}
            columns={[
              {
                label: "Название замка",
                key: "display_name",
                type: ColumnType.String,
                sortable: true
              },
              {
                label: "Система",
                key: "lock_system",
                type: ColumnType.LockSystem,
                sortable: true
              },
              {
                label: "Сервисный ID",
                key: "lock_service_id",
                type: ColumnType.String,
                sortable: true
              },
              {
                key: "actions",
                label: "Действия",
                type: ColumnType.Actions,
                actions: [
                  {
                    icon: <FaPencil />,
                    onClick: (_value, row) => { editLock(row) }
                  },
                  {
                    icon: <FaLock />,
                    onClick: (_value, row) => { navigate(`/dashboard/locks/${row.id}`) }
                  }
                ]
              }              
            ]}
            data={locks || []}
            rowsPerPage={10}
          />
        </div>
      </div>
    </>
  );
}