import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { toast } from "react-toastify";
import { api } from "../../../../api";
import { getIdFromRenterHandbook } from "../../../../utils/getIdFromHandbooks";
import useErrorHandling from "../../../../hooks/useErrorHandling";
import { useState } from "react";

interface IBillingCreateForm {
  number: number;
  date: string;
  payer: number;
  provider: number;
  amount: number;
  paid: boolean;
  description: string;
}

const createBillingFields: InputField[] = [
  { label: "Номер", name: "number", placeholder: "Номер", type: "text", isRequired: true },
  { label: "Дата", name: "date", placeholder: "Дата", type: "datetime-local", isRequired: true },
  { label: "Плательщик", name: "payer", placeholder: "Плательщик", type: "renter", isRequired: true },
  { label: "Поставщик", name: "provider", placeholder: "Поставщик", type: "text", isRequired: true },
  { label: "Сумма", name: "amount", placeholder: "Сумма", type: "text", isRequired: true },
  { label: "Оплачено", name: "paid", placeholder: "Оплачено", type: "text", isRequired: true },
  { label: "Описание", name: "description", placeholder: "Описание", type: "text", isRequired: false },
];

export function CreateBillModal({ disclosure }: { disclosure: ReturnType<typeof useDisclosure> }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);

  const handleError = useErrorHandling();

  const onSubmit = async (data: IBillingCreateForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const payer = await getIdFromRenterHandbook(String(data.payer));

      api.billings.create({
          number: data.number,
          date: data.date,
          payer: Number(payer),
          provider: data.provider,
          amount: data.amount,
          paid: data.paid,
          description: data.description,
        })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder title="Создание биллинга" modalErrors={modalErrors} fields={createBillingFields} isOpen={isOpen} onOpenChange={onOpenChange} isSensitiveData={false} onSubmit={onSubmit} />
  );
}
